<template>
  <div>
    <div class="box space-card">
      <p class="title">
        Regla Comercial
      </p>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-3">
              <b-field label="Tasa Interes" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="tasa1"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="Monto" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="montoDeuda"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-6">
              <b-field label="Tiempo" :label-position="labelPosition">
                <b-input type="number" placeholder="9" v-model="periodoN">
                </b-input>
                <b-select
                  placeholder="Selecciona un tipo"
                  expanded
                  v-model="periodo"
                >
                  <option
                    :value="item.id"
                    v-for="(item, index) in periodos"
                    :key="index"
                    >{{ item.text }}</option
                  >
                </b-select>
              </b-field>
            </div>
          </div>
          <div v-for="(pago, index) in pagos" :key="index">
            <p class="title is-6">Pago Nro. {{ index + 1 }}</p>
            <div class="columns">
              <div class="column is-3">
                <b-field label="Monto" :label-position="labelPosition">
                  <b-input
                    type="number"
                    placeholder="Escribe un valor"
                    v-model="pago.monto"
                  >
                  </b-input>
                </b-field>
              </div>
              <div class="column is-6">
                <b-field label="Tiempo" :label-position="labelPosition">
                  <b-input
                    type="number"
                    placeholder="9"
                    v-model="pago.periodoN"
                  >
                  </b-input>
                  <b-select
                    placeholder="Selecciona un tipo"
                    expanded
                    v-model="pago.periodo"
                  >
                    <option
                      :value="item.id"
                      v-for="(item, index) in periodos"
                      :key="index"
                      >{{ item.text }}</option
                    >
                  </b-select>
                </b-field>
              </div>
              <div class="column is-3">
                <b-field
                  :label="`Resultado Pago Nro. ${index + 1}`"
                  :label-position="labelPosition"
                >
                  <p class="title is-5">
                    {{ pago.resPago | round }}
                  </p>
                </b-field>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <b-button type="is-primary" expanded @click="calcular"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <p class="title is-5">Valor Futuro: {{ valorFuturo | round }}</p>
            <p class="title is-5">Tiempo: {{ tiempo1 | round }}</p>
            <p class="title is-5">Monto: {{ montoFinal | round }}</p>
            <p class="title is-5">Pago Final: {{ pagoFinal | round }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  calcularPeriodo,
  calcularValorFuturo,
  calcularMontoPago,
  calcularInteresesDos,
} from '../services/index.js'

export default {
  data() {
    return {
      selected: new Date(),
      showWeekNumber: false,
      locale: undefined,
      labelPosition: 'on-border',
      periodos: [
        { text: 'n/a', id: 0, m: 0 },
        { text: 'Años', id: 1, m: 1 },
        { text: 'Semestres', id: 2, m: 2 },
        { text: 'Cuatrimetres', id: 3, m: 3 },
        { text: 'Trimestres', id: 4, m: 4 },
        { text: 'Bimestres', id: 5, m: 6 },
        { text: 'Meses', id: 6, m: 12 },
        { text: 'Quincenas', id: 7, m: 24 },
        { text: 'Semanas', id: 8, m: 52 },
        { text: 'Días Exactos', id: 9, m: 365 },
        { text: 'Días Aproximados', id: 10, m: 360 },
      ],
      tipos_interes: [
        { text: 'Simple Ordinario', id: 1 },
        { text: 'Exacto', id: 2 },
        { text: 'Bancario', id: 3 },
      ],

      pagos: [
        { monto: 0, periodoN: 0, periodo: 0, resPago: 0 },
        { monto: 0, periodoN: 0, periodo: 0, resPago: 0 },
        { monto: 0, periodoN: 0, periodo: 0, resPago: 0 },
        { monto: 0, periodoN: 0, periodo: 0, resPago: 0 },
        { monto: 0, periodoN: 0, periodo: 0, resPago: 0 },
        { monto: 0, periodoN: 0, periodo: 0, resPago: 0 },
        { monto: 0, periodoN: 0, periodo: 0, resPago: 0 },
        { monto: 0, periodoN: 0, periodo: 0, resPago: 0 },
        { monto: 0, periodoN: 0, periodo: 0, resPago: 0 },
        { monto: 0, periodoN: 0, periodo: 0, resPago: 0 },
      ],
      tasa1: 0,
      montoDeuda: 0,
      periodoN: 0,
      periodo: 0,
      valorFuturo: 0,
      tiempo1: 0,
      montoFinal: 0,
      pagoFinal: 0,
    }
  },
  methods: {
    async calcular() {
      this.tiempo1 = await calcularPeriodo(
        this.periodos.find((p) => p.id == this.periodo).m,
        this.periodoN
      )

      this.valorFuturo = await calcularValorFuturo(
        this.montoDeuda,
        this.tasa1,
        this.tiempo1
      )

      for (let i of this.pagos) {
        i.resPago = await calcularMontoPago(
          i.monto,
          this.tasa1,
          this.periodoN,
          calcularPeriodo(
            this.periodos.find((p) => p.id == i.periodo).m,
            i.periodoN
          )
        )
        this.montoFinal += i.resPago
      }

      this.pagoFinal = await calcularInteresesDos(
        this.valorFuturo,
        this.montoFinal
      )
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import VueBus from 'vue-bus'

Vue.use(VueBus)
Vue.use(Buefy)

Vue.filter('abs', function(value) {
  if (!value) return 0
  return Math.abs(value)
})

Vue.filter('x100', function(value) {
  if (!value) return 0
  return Math.abs(value) * 100
})
Vue.filter('round', function(value) {
  if (!value) return 0
  return Math.round(value * 100) / 100
})
Vue.filter('round3', function(value) {
  if (!value) return 0
  return Math.round(value * 1000) / 1000
})
Vue.filter('round4', function(value) {
  if (!value) return 0
  return Math.round(value * 10000) / 10000
})

Vue.config.productionTip = false

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')

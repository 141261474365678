<template>
  <div>
    <div class="box space-card">
      <p class="title">
        Cuando se ocupa el valor futuro o los intereses ganados
      </p>
      <p class="subtitle">Valor Futuro</p>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field label="Capital (C)" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe una capital"
                  v-model="capital1"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Fecha de Inicio" :label-position="labelPosition">
                <b-datepicker
                  v-model="fechaInicial1"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Fecha de Fin" :label-position="labelPosition">
                <b-datepicker
                  v-model="fechaFinal1"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4">
              <b-field label="Tasa Interés (i)" :label-position="labelPosition">
                <b-input type="number" placeholder="12,00%" v-model="tasa1">
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Tipo Interés" :label-position="labelPosition">
                <b-select
                  placeholder="Selecciona un tipo"
                  expanded
                  v-model="tipoInteres"
                >
                  <option
                    :value="item.id"
                    v-for="(item, index) in tipos_interes"
                    :key="index"
                    >{{ item.text }}</option
                  >
                </b-select>
              </b-field>
            </div>
            <div class="column is-4">
              <b-button type="is-primary" expanded @click="calcular1()"
                >CALCULAR</b-button
              >
            </div>
          </div>
          <div>
            <p class="title is-5">Tiempo en años (t): {{ tiempo1 | round }}</p>
            <p class="title is-5">Intereses (I): {{ intereses1 | round }}</p>
            <p class="title is-5">
              Valor futuro (S): {{ valorFuturo1 | round }}
            </p>
          </div>
        </div>
      </div>

      <br />
      <hr />
      <br />

      <p class="subtitle">Interes Ganados</p>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field label="Capital (C)" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe una capital"
                  v-model="capital2"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Tasa Interés (i)" :label-position="labelPosition">
                <b-input type="number" placeholder="12,00%" v-model="tasa2">
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Fecha de Inicio" :label-position="labelPosition">
                <b-datepicker
                  v-model="fechaInicial2"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
          </div>
          <div v-for="(tiempo, index) in tiempos" :key="index">
            <div class="columns">
              <div class="column is-6">
                <b-field
                  :label="`Tiempo Nro. ${index + 1}`"
                  :label-position="labelPosition"
                >
                  <b-input
                    type="number"
                    placeholder="9"
                    v-model="tiempo.periodoN"
                  >
                  </b-input>
                  <b-select
                    placeholder="Selecciona un tipo"
                    expanded
                    v-model="tiempo.periodo"
                  >
                    <option
                      :value="item.id"
                      v-for="(item, index) in periodos"
                      :key="index"
                      >{{ item.text }}</option
                    >
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <b-button type="is-primary" expanded @click="calcular2()"
                >CALCULAR</b-button
              >
            </div>
          </div>
          <div>
            <p class="title is-5">Tiempo en años (t): {{ tiempo2 | round }}</p>
            <p class="title is-5">Intereses (I): {{ intereses2 | round }}</p>
            <p class="title is-5">
              Valor futuro (S): {{ valorFuturo2 | round }}
            </p>
            <p class="title is-5">Fecha final: {{ fechaFinal2 }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  calcularTiempo,
  calcularPeriodo,
  calcularIntereses,
  calcularValorFuturo,
  calcularFechaFinal,
} from '../services/index.js'

export default {
  data() {
    return {
      selected: new Date(),
      showWeekNumber: false,
      locale: undefined,
      labelPosition: 'on-border',
      periodos: [
        { text: 'n/a', id: 0, m: 0 },
        { text: 'Años', id: 1, m: 1 },
        { text: 'Semestres', id: 2, m: 2 },
        { text: 'Cuatrimetres', id: 3, m: 3 },
        { text: 'Trimestres', id: 4, m: 4 },
        { text: 'Bimestres', id: 5, m: 6 },
        { text: 'Meses', id: 6, m: 12 },
        { text: 'Quincenas', id: 7, m: 24 },
        { text: 'Semanas', id: 8, m: 52 },
        { text: 'Días Exactos', id: 9, m: 365 },
        { text: 'Días Aproximados', id: 10, m: 360 },
      ],
      tipos_interes: [
        { text: 'Simple Ordinario', id: 1 },
        { text: 'Exacto', id: 2 },
        { text: 'Bancario', id: 3 },
      ],

      //1
      capital1: 0,
      fechaInicial1: new Date(),
      fechaFinal1: new Date(),
      tasa1: 0,
      tipoInteres: null,
      tiempo1: 0,
      intereses1: 0,
      valorFuturo1: 0,

      //2
      capital2: 0,
      fechaInicial2: new Date(),
      fechaFinal2: null,
      tasa2: 0,
      tiempos: [],
      periodoN: 0,
      periodo: 0,
      tiempo2: 0,
      intereses2: 0,
      valorFuturo2: 0,
    }
  },
  created() {
    for (let i = 1; i <= 3; i++)
      this.tiempos.push({
        periodoN: 0,
        periodo: 0,
      })
  },
  methods: {
    async calcular1() {
      this.tiempo1 = await calcularTiempo(
        this.tipoInteres,
        this.fechaInicial1,
        this.fechaFinal1
      )
      this.intereses1 = await calcularIntereses(
        this.capital1,
        this.tiempo1,
        this.tasa1
      )
      this.valorFuturo1 = await calcularValorFuturo(
        this.capital1,
        this.tasa1,
        this.tiempo1
      )
    },

    async calcular2() {
      this.tiempo2 = 0

      for (let i of this.tiempos) {
        if (i.periodoN > 0) {
          this.tiempo2 += await calcularPeriodo(
            this.periodos.find((p) => p.id == i.periodo).m,
            i.periodoN
          )
        }
      }

      this.intereses2 = await calcularIntereses(
        this.capital2,
        this.tiempo2,
        this.tasa2
      )
      this.valorFuturo2 = await calcularValorFuturo(
        this.capital2,
        this.tasa2,
        this.tiempo2
      )

      this.fechaFinal2 = await calcularFechaFinal(
        this.fechaInicial2,
        this.tiempo2
      )
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>

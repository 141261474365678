<template>
  <div>
    <div class="box space-card">
      <p class="title">
        Prueba Pendiente
      </p>
      <img src="img/pruebaPendiente.png" alt="" />
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field
                label="Pendiente de la recta"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="pendienteRecta"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field
                label="Error estándar de la estimación de la pendiente"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="errorEstandar"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field
                label="Tamaño de la muestra"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="muestra"
                >
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4">
              <b-field
                label="Nivel de significancia"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="nivelSignificancia"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Colas" :label-position="labelPosition">
                <b-select
                  placeholder="Selecciona una cola"
                  expanded
                  v-model="cola"
                >
                  <option
                    :value="item.id"
                    v-for="(item, index) in colas"
                    :key="index"
                    >{{ item.text }}</option
                  >
                </b-select>
              </b-field>
            </div>
            <div class="column is-4">
              <b-button type="is-primary" expanded @click="calcular"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <p class="title is-5">T Calculado: {{ tCalculado | round }}</p>
            <p class="title is-5">
              Grado Libertad: {{ gradoLibertad | round }}
            </p>
            <p class="title is-5">
              Cola Izquierda: {{ colaI | round }} --> Decisión:
              {{ decisionI }}
            </p>
            <p class="title is-5">
              Cola Derecha: {{ colaD | round }} --> Decisión:
              {{ decisionD }}
            </p>
            <p class="title is-5">
              Dos Colas: {{ dosColas | round }} | {{ dosColas2 | round }} -->
              Decisión:
              {{ decisionDos }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  calcularDivision,
  calcularInteresesDos,
  calcularInvT,
} from '../services/index.js'

export default {
  data() {
    return {
      labelPosition: 'on-border',
      periodos: [
        { text: 'n/a', id: 0, m: 0 },
        { text: 'Años', id: 1, m: 1 },
        { text: 'Semestres', id: 2, m: 2 },
        { text: 'Cuatrimetres', id: 3, m: 3 },
        { text: 'Trimestres', id: 4, m: 4 },
        { text: 'Bimestres', id: 5, m: 6 },
        { text: 'Meses', id: 6, m: 12 },
        { text: 'Quincenas', id: 7, m: 24 },
        { text: 'Semanas', id: 8, m: 52 },
        { text: 'Días Exactos', id: 9, m: 365 },
        { text: 'Días Aproximados', id: 10, m: 360 },
      ],
      tipos_interes: [
        { text: 'Simple Ordinario', id: 1 },
        { text: 'Exacto', id: 2 },
        { text: 'Bancario', id: 3 },
      ],
      colas: [
        { id: 1, text: 'Cola Derecha' },
        { id: 2, text: 'Cola Izquierda' },
        { id: 3, text: 'Dos Colas' },
      ],

      pendienteRecta: 0,
      errorEstandar: 0,
      muestra: 0,
      nivelSignificancia: 0,
      cola: null,
      tCalculado: 0,
      gradoLibertad: 0,
      colaI: null,
      decisionI: '',
      colaD: null,
      decisionD: '',
      dosColas: null,
      dosColas2: null,
      decisionDos: '',
    }
  },
  methods: {
    async calcular() {
      this.tCalculado = calcularDivision(
        this.pendienteRecta,
        this.errorEstandar
      )
      this.gradoLibertad = calcularInteresesDos(this.muestra, 2)

      this.colaI = 0
      this.colaD = 0
      this.dosColas = 0
      this.dosColas2 = 0
      this.decisionI = ''
      this.decisionD = ''
      this.decisionDos = ''

      if (this.cola == 1) {
        this.colaD = calcularInvT(
          this.cola,
          this.nivelSignificancia,
          this.gradoLibertad
        )
        this.decisionD =
          this.tCalculado > this.colaD ? 'Se rechaza Ho' : 'Se acepta Ho'
      } else if (this.cola == 2) {
        this.colaI = calcularInvT(
          this.cola,
          this.nivelSignificancia,
          this.gradoLibertad
        )
        this.decisionI =
          this.tCalculado < this.colaI ? 'Se rechaza Ho' : 'Se acepta Ho'
      } else {
        this.dosColas = calcularInvT(
          3,
          this.nivelSignificancia,
          this.gradoLibertad
        )
        this.dosColas2 = calcularInvT(
          4,
          this.nivelSignificancia,
          this.gradoLibertad
        )
        this.decisionDos =
          Math.abs(this.tCalculado) > Math.abs(this.dosColas)
            ? 'Se rechaza Ho'
            : 'Se acepta Ho'
      }
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>

<template>
  <div class="space-all">
    <!-- <div class="columns">
      <div class="column">
        <div class="columns is-mobile">
          <div class="column is-8">
            <b-input
              placeholder="Escribe un nombre"
              icon="search"
              v-model="search"
              icon-pack="fas"
              @keyup.native="searchName()"
            >
            </b-input>
          </div>
          <div class="column">
            <b-button type="is-success" expanded @click="openModelCreate()"
              >Crear cliente</b-button
            >
          </div>
        </div>
        <table-view :data="data"></table-view>
      </div>
    </div> -->

    <b-tabs position="is-centered" type="is-boxed" class="block" v-model="tab">
      <b-tab-item label="Días">
        <exactos-funcion-dias></exactos-funcion-dias>
        <exactos-funcion-dias-tres-sesenta></exactos-funcion-dias-tres-sesenta>
        <exactos-banqueros></exactos-banqueros>
      </b-tab-item>
      <b-tab-item label="Interes Simple S">
        <interes-simple-s-uno></interes-simple-s-uno>
        <interes-simple-s-dos></interes-simple-s-dos>
        <interes-simple-s-tres></interes-simple-s-tres>
        <interes-simple-s-cuatro></interes-simple-s-cuatro>
      </b-tab-item>
      <b-tab-item label="Interes Simple I">
        <interes-simple-i-uno></interes-simple-i-uno>
        <interes-simple-i-dos></interes-simple-i-dos>
        <interes-simple-i-tres></interes-simple-i-tres>
      </b-tab-item>
      <b-tab-item label="Descuento">
        <descuento-uno></descuento-uno>
        <descuento-dos></descuento-dos>
        <descuento-tres></descuento-tres>
        <descuento-cuatro></descuento-cuatro>
      </b-tab-item>
      <b-tab-item label="Pago Parciales">
        <pagos-parciales></pagos-parciales>
        <pagos-parciales-dos></pagos-parciales-dos>
        <pagos-parciales-tres></pagos-parciales-tres>
      </b-tab-item>
      <b-tab-item label="Series Pagos">
        <series-pagos-uno></series-pagos-uno>
        <series-pagos-dos></series-pagos-dos>
      </b-tab-item>
      <b-tab-item label="Flj. Efec. por Fechas">
        <flujo-efectivo></flujo-efectivo>
      </b-tab-item>
      <b-tab-item label="Prueba Pendiente">
        <prueba-pendiente></prueba-pendiente>
      </b-tab-item>
      <b-tab-item label="Hipo">
        <hipo-uno-muestra-z></hipo-uno-muestra-z>
        <hipo-uno-muestra-t></hipo-uno-muestra-t>
        <hipo-uno-muestra-t-r></hipo-uno-muestra-t-r>
      </b-tab-item>
      <b-tab-item label="Dos Media">
        <dos-media-u-uno></dos-media-u-uno>
        <dos-media-u-dos></dos-media-u-dos>
        <dos-media-u-tres></dos-media-u-tres>
        <dos-media-u-cuatro></dos-media-u-cuatro>
        <dos-media-u-cinco></dos-media-u-cinco>
        <dos-media-u-seis></dos-media-u-seis>
      </b-tab-item>
      <b-tab-item label="Estimación">
        <est-des-uno></est-des-uno>
        <est-des-dos></est-des-dos>
        <est-des-tres></est-des-tres>
        <est-des-cuatro></est-des-cuatro>
        <est-des-cinco></est-des-cinco>
        <est-des-seis></est-des-seis>
        <est-des-siete></est-des-siete>
      </b-tab-item>
      <b-tab-item label="Probabilidad">
        <prob-uno></prob-uno>
        <prob-dos></prob-dos>
        <prob-tres></prob-tres>
        <prob-cuatro></prob-cuatro>
        <prob-cinco></prob-cinco>
      </b-tab-item>
      <b-tab-item label="Dist. Continua">
        <dist-cont-uno></dist-cont-uno>
        <dist-cont-dos></dist-cont-dos>
        <dist-cont-tres></dist-cont-tres>
      </b-tab-item>
    </b-tabs>

    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import TableView from '@/components/Table.vue'

import ExactosFuncionDias from './Exacto_Funcion_Dias'
import ExactosFuncionDiasTresSesenta from './Exacto_Funcion_Dias360'
import ExactosBanqueros from './Exacto_Banqueros'

import InteresSimpleSUno from './Interes_Simple_S_Uno'
import InteresSimpleSDos from './Interes_Simple_S_Dos'
import InteresSimpleSTres from './Interes_Simple_S_Tres'
import InteresSimpleSCuatro from './Interes_Simple_S_Cuatro'

import InteresSimpleIUno from './Interes_Simple_I_Uno'
import InteresSimpleIDos from './Interes_Simple_I_Dos'
import InteresSimpleITres from './Interes_Simple_I_Tres'

import DescuentoUno from './Descuento_1'
import DescuentoDos from './Descuento_2'
import DescuentoTres from './Descuento_3'
import DescuentoCuatro from './Descuento_4'

import PagosParciales from './PagosParciales'
import PagosParcialesDos from './PagosParcialesDos'
import PagosParcialesTres from './PagosParcialesTres'

import SeriesPagosUno from './SeriesPagosUno'
import SeriesPagosDos from './SeriesPagosDos'

import FlujoEfectivo from './FlujoEfectivo'

import PruebaPendiente from './PruebaPendiente'

import HipoUnoMuestraZ from './HipoUnoMuestraZ'
import HipoUnoMuestraT from './HipoUnoMuestraT'
import HipoUnoMuestraTR from './HipoUnoMuestraTR'

import DosMediaUUno from './DosMediaU1'
import DosMediaUDos from './DosMediaU2'
import DosMediaUTres from './DosMediaU3'
import DosMediaUCuatro from './DosMediaU4'
import DosMediaUCinco from './DosMediaU5'
import DosMediaUSeis from './DosMediaU6'

import EstDesUno from './EstDes1'
import EstDesDos from './EstDes2'
import EstDesTres from './EstDes3'
import EstDesCuatro from './EstDes4'
import EstDesCinco from './EstDes5'
import EstDesSeis from './EstDes6'
import EstDesSiete from './EstDes7'

import ProbUno from './Prob1'
import ProbDos from './Prob2'
import ProbTres from './Prob3'
import ProbCuatro from './Prob4'
import ProbCinco from './Prob5'

import DistContUno from './DistCont1'
import DistContDos from './DistCont2'
import DistContTres from './DistCont3'

export default {
  name: 'Home',
  data() {
    return {
      isComponentModalActive: false,
      isLoading: false,
      data: [],
      isEdit: false,
      dataEdit: {},
      search: '',
      tab: 12,
    }
  },
  components: {
    TableView,
    ExactosFuncionDias,
    ExactosFuncionDiasTresSesenta,
    ExactosBanqueros,
    InteresSimpleSUno,
    InteresSimpleSDos,
    InteresSimpleSTres,
    InteresSimpleSCuatro,
    InteresSimpleIUno,
    InteresSimpleIDos,
    InteresSimpleITres,
    DescuentoUno,
    DescuentoDos,
    DescuentoTres,
    DescuentoCuatro,
    PagosParciales,
    PagosParcialesDos,
    PagosParcialesTres,
    SeriesPagosUno,
    SeriesPagosDos,
    FlujoEfectivo,
    PruebaPendiente,
    HipoUnoMuestraZ,
    HipoUnoMuestraT,
    HipoUnoMuestraTR,
    DosMediaUUno,
    DosMediaUDos,
    DosMediaUTres,
    DosMediaUCuatro,
    DosMediaUCinco,
    DosMediaUSeis,
    EstDesUno,
    EstDesDos,
    EstDesTres,
    EstDesCuatro,
    EstDesCinco,
    EstDesSeis,
    EstDesSiete,
    ProbUno,
    ProbDos,
    ProbTres,
    ProbCuatro,
    ProbCinco,
    DistContUno,
    DistContDos,
    DistContTres,
  },
  methods: {},

  created() {
    //Bus
    // this.$bus.on('refreshUsers', () => this.list())
    // this.$bus.on('openEditModal', (user) => {
    //   this.dataEdit = user
    //   this.isEdit = true
    //   this.isComponentModalActive = true
    // })
  },
  mounted() {
    let b = document.getElementsByClassName('input')
    b.forEach((i) => i.setAttribute('step', '0.01'))
  },
}
</script>

<style scoped>
.space-all {
  margin: 6% 5% 0 5%;
}

.space-card {
  margin: 0 10% 0 10%;
}

.top-space {
  margin-top: 3%;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <div>
    <div class="box space-card">
      <p class="title">
        Dist. Prob. Discreta Frecuencia
      </p>
      <div class="columns">
        <div class="column is-4">
          <img src="img/prob3.png" alt="" />
        </div>
        <div class="column is-4">
          <img src="img/prob4.png" alt="" />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="columns" v-for="(item, index) in listProb" :key="index">
            <!-- <div class="column is-1">
              <b-field label="Nro." :label-position="labelPosition">
                <p class="title is-5">{{ index + 1 }}</p>
              </b-field>
            </div> -->
            <div class="column is-1">
              <b-field label="x" :label-position="labelPosition">
                <b-input type="text" placeholder="..." v-model="item.x">
                </b-input>
              </b-field>
            </div>
            <div class="column is-1">
              <b-field label="F(x)" :label-position="labelPosition">
                <b-input type="text" placeholder="..." v-model="item.fx">
                </b-input>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field label="P(x)" :label-position="labelPosition">
                <p class="title is-5 has-text-centered">
                  {{ item.px | round }}
                </p>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field label="x*P(x)" :label-position="labelPosition">
                <p class="title is-5 has-text-centered">
                  {{ item.xpx | round }}
                </p>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field label="(x-μ)" :label-position="labelPosition">
                <p class="title is-5 has-text-centered">
                  {{ item.xu | round }}
                </p>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field label="(x-μ)2" :label-position="labelPosition">
                <p class="title is-5 has-text-centered">
                  {{ item.xu2 | round }}
                </p>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field label="(x-μ)2*P(x)" :label-position="labelPosition">
                <p class="title is-5 has-text-centered">
                  {{ item.xu2px | round }}
                </p>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-2">
              <b-button
                expanded
                icon-left="plus-circle"
                icon-pack="fas"
                type="is-success"
                @click="addListProb()"
              >
                Añadir
              </b-button>
            </div>
          </div>

          <hr />

          <div class="columns">
            <div class="column is-12">
              <b-button type="is-primary" expanded @click="calcular"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <p class="title is-5">n: {{ n | round }}</p>
            <p class="title is-5">Total F(x): {{ fx | round }}</p>
            <p class="title is-5">Total P(x): {{ px | round }}</p>
            <hr />
            <p class="title is-5">Media μ: {{ media | round }}</p>
            <p class="title is-5">Varianza σ2: {{ varianza | round }}</p>
            <p class="title is-5">
              Desviación Estandar σ: {{ desviacionEst | round }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  calcularDivision,
  calcularInteresesDos as calcularResta,
  calcularInvT,
  calcularInvNormEstand,
  calcularZCalculado,
  calcularPromedioArray,
  calcularDesviacionMuestral,
  calcularVarianzaMedias,
  calcularZCalculadoDosMedias,
  calcularPromedioArrayJson,
  calcularVarianzaConjunta,
  calcularTCalculadoDosMedias,
  calcularSuma,
  calcularGradoLibertadDif,
  calcularTCalculadoDosMediasDif,
  calcularInvNormEstandEstDes,
  calcularNEstDes,
  calcularNEstPro,
  calcularErrorEstandarEnvConfMediaZ,
  calcularTiempoOcupaFuturo as calcularMultiplicacion,
  calcularFactorCorreccionInvConfZMediaFinita,
  calcularErrorEstandarEnvConfZProporcion,
  calcularRaiz,
} from '../services/index.js'

export default {
  data() {
    return {
      labelPosition: 'on-border',
      periodos: [
        { text: 'n/a', id: 0, m: 0 },
        { text: 'Años', id: 1, m: 1 },
        { text: 'Semestres', id: 2, m: 2 },
        { text: 'Cuatrimetres', id: 3, m: 3 },
        { text: 'Trimestres', id: 4, m: 4 },
        { text: 'Bimestres', id: 5, m: 6 },
        { text: 'Meses', id: 6, m: 12 },
        { text: 'Quincenas', id: 7, m: 24 },
        { text: 'Semanas', id: 8, m: 52 },
        { text: 'Días Exactos', id: 9, m: 365 },
        { text: 'Días Aproximados', id: 10, m: 360 },
      ],
      tipos_interes: [
        { text: 'Simple Ordinario', id: 1 },
        { text: 'Exacto', id: 2 },
        { text: 'Bancario', id: 3 },
      ],
      colas: [
        { id: 1, text: 'Cola Derecha' },
        { id: 2, text: 'Cola Izquierda' },
        { id: 3, text: 'Dos Colas' },
      ],
      lista: [0],
      datos: [0],
      listProb: [
        {
          x: null,
          fx: null,
          px: null,
          xpx: null,
          xu: null,
          xu2: null,
          xu2px: null,
        },
      ],

      nivelConfianza: 0,
      media: 0,
      desviacionEst: null,
      n: 0,
      p: 0,
      N: 0,
      px: 0,
      fx: 0,
      varianza: 0,

      Z: null,
      errorEstandar: null,
      errorMuestral: null,
      factorCorreccion: null,
      limiteSuperior: null,
      limiteInferior: null,
      promedio: null,
      t: null,
      gradoLibertad: null,
    }
  },
  methods: {
    async calcular() {
      this.n = this.listProb.filter((i) => i.x >= 0 && i.fx >= 0).length
      this.fx = this.listProb
        .filter((i) => i.x >= 0 && i.fx >= 0)
        .map((i) => parseFloat(i.fx))
        .reduce((a, b) => a + b)

      for (let i of this.listProb.filter((i) => i.x >= 0 && i.fx >= 0)) {
        i.px = calcularDivision(i.fx, this.fx)
        i.xpx = i.x * i.px

        this.px = calcularSuma(this.px, i.px)
        this.media = calcularSuma(this.media, i.xpx)
      }

      for (let i of this.listProb.filter((i) => i.x >= 0 && i.fx >= 0)) {
        i.xu = calcularResta(i.x, this.media)
        i.xu2 = calcularMultiplicacion(i.xu, i.xu)
        i.xu2px = calcularMultiplicacion(i.xu2, i.px)

        this.varianza = calcularSuma(this.varianza, i.xu2px)
      }

      this.desviacionEst = calcularRaiz(this.varianza)
    },
    addListProb() {
      this.listProb.push({
        x: null,
        fx: null,
        px: null,
        xpx: null,
        xu: null,
        xu2: null,
        xu2px: null,
      })
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>

import ExactosFuncionDias from '../views/Exacto_Funcion_Dias'
import ExactosFuncionDiasTresSesenta from '../views/Exacto_Funcion_Dias360'
import ExactosBanqueros from '../views/Exacto_Banqueros'

import InteresSimpleSUno from '../views/Interes_Simple_S_Uno'
import InteresSimpleSDos from '../views/Interes_Simple_S_Dos'
import InteresSimpleSTres from '../views/Interes_Simple_S_Tres'
import InteresSimpleSCuatro from '../views/Interes_Simple_S_Cuatro'

import InteresSimpleIUno from '../views/Interes_Simple_I_Uno'
import InteresSimpleIDos from '../views/Interes_Simple_I_Dos'
import InteresSimpleITres from '../views/Interes_Simple_I_Tres'

import DescuentoUno from '../views/Descuento_1'
import DescuentoDos from '../views/Descuento_2'
import DescuentoTres from '../views/Descuento_3'
import DescuentoCuatro from '../views/Descuento_4'

import PagosParciales from '../views/PagosParciales'
import PagosParcialesDos from '../views/PagosParcialesDos'
import PagosParcialesTres from '../views/PagosParcialesTres'

import SeriesPagosUno from '../views/SeriesPagosUno'
import SeriesPagosDos from '../views/SeriesPagosDos'

import FlujoEfectivo from '../views/FlujoEfectivo'

import PruebaPendiente from '../views/PruebaPendiente'

import HipoUnoMuestraZ from '../views/HipoUnoMuestraZ'
import HipoUnoMuestraT from '../views/HipoUnoMuestraT'
import HipoUnoMuestraTR from '../views/HipoUnoMuestraTR'

import DosMediaUUno from '../views/DosMediaU1'
import DosMediaUDos from '../views/DosMediaU2'
import DosMediaUTres from '../views/DosMediaU3'
import DosMediaUCuatro from '../views/DosMediaU4'
import DosMediaUCinco from '../views/DosMediaU5'
import DosMediaUSeis from '../views/DosMediaU6'

import EstDesUno from '../views/EstDes1'
import EstDesDos from '../views/EstDes2'
import EstDesTres from '../views/EstDes3'
import EstDesCuatro from '../views/EstDes4'
import EstDesCinco from '../views/EstDes5'
import EstDesSeis from '../views/EstDes6'
import EstDesSiete from '../views/EstDes7'

import ProbUno from '../views/Prob1'
import ProbDos from '../views/Prob2'
import ProbTres from '../views/Prob3'
import ProbCuatro from '../views/Prob4'
import ProbCinco from '../views/Prob5'

import DistContUno from '../views/DistCont1'
import DistContDos from '../views/DistCont2'
import DistContTres from '../views/DistCont3'

const components = {
  ExactosFuncionDias,
  ExactosFuncionDiasTresSesenta,
  ExactosBanqueros,
  InteresSimpleSUno,
  InteresSimpleSDos,
  InteresSimpleSTres,
  InteresSimpleSCuatro,
  InteresSimpleIUno,
  InteresSimpleIDos,
  InteresSimpleITres,
  DescuentoUno,
  DescuentoDos,
  DescuentoTres,
  DescuentoCuatro,
  PagosParciales,
  PagosParcialesDos,
  PagosParcialesTres,
  SeriesPagosUno,
  SeriesPagosDos,
  FlujoEfectivo,
  PruebaPendiente,
  HipoUnoMuestraZ,
  HipoUnoMuestraT,
  HipoUnoMuestraTR,
  DosMediaUUno,
  DosMediaUDos,
  DosMediaUTres,
  DosMediaUCuatro,
  DosMediaUCinco,
  DosMediaUSeis,
  EstDesUno,
  EstDesDos,
  EstDesTres,
  EstDesCuatro,
  EstDesCinco,
  EstDesSeis,
  EstDesSiete,
  ProbUno,
  ProbDos,
  ProbTres,
  ProbCuatro,
  ProbCinco,
  DistContUno,
  DistContDos,
  DistContTres,
}
export default {
  functional: true,
  props: ['name'],
  render(h, ctx) {
    return h(components[ctx.props.name], ctx.data, ctx.children)
  },
}

<template>
  <div>
    <div class="box space-card">
      <p class="title">
        Inv Conf Z Media Finita
      </p>
      <div class="columns">
        <div class="column is-4">
          <img src="img/invconfzmediafinita.png" alt="" />
        </div>
        <!-- <div class="column is-4">
          <img src="img/dosmediasutdiferenteresums2.png" alt="" />
        </div> -->
      </div>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field
                label="Nivel de Confianza"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="nivelConfianza"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Media" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="media"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field
                label="Desviación Estandar"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="desviacionEst"
                >
                </b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column is-4">
              <b-field label="n" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="n"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="N" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="N"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-button type="is-primary" expanded @click="calcular"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <p class="title is-5">Z: {{ Z | round }}</p>
            <p class="title is-5">
              Error Estandar: {{ errorEstandar | round }}
            </p>
            <p class="title is-5">
              Error Muestral: {{ errorMuestral | round }}
            </p>
            <p class="title is-5">
              Factor de Corrección: {{ factorCorreccion | round }}
            </p>
            <p class="title is-5">
              Limite Inferior: {{ limiteInferior | round }}
            </p>
            <p class="title is-5">
              Limite Superior: {{ limiteSuperior | round }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  calcularDivision,
  calcularInteresesDos as calcularResta,
  calcularInvT,
  calcularInvNormEstand,
  calcularZCalculado,
  calcularPromedioArray,
  calcularDesviacionMuestral,
  calcularVarianzaMedias,
  calcularZCalculadoDosMedias,
  calcularPromedioArrayJson,
  calcularVarianzaConjunta,
  calcularTCalculadoDosMedias,
  calcularSuma,
  calcularGradoLibertadDif,
  calcularTCalculadoDosMediasDif,
  calcularInvNormEstandEstDes,
  calcularNEstDes,
  calcularNEstPro,
  calcularErrorEstandarEnvConfMediaZ,
  calcularTiempoOcupaFuturo as calcularMultiplicacion,
  calcularFactorCorreccionInvConfZMediaFinita,
} from '../services/index.js'

export default {
  data() {
    return {
      labelPosition: 'on-border',
      periodos: [
        { text: 'n/a', id: 0, m: 0 },
        { text: 'Años', id: 1, m: 1 },
        { text: 'Semestres', id: 2, m: 2 },
        { text: 'Cuatrimetres', id: 3, m: 3 },
        { text: 'Trimestres', id: 4, m: 4 },
        { text: 'Bimestres', id: 5, m: 6 },
        { text: 'Meses', id: 6, m: 12 },
        { text: 'Quincenas', id: 7, m: 24 },
        { text: 'Semanas', id: 8, m: 52 },
        { text: 'Días Exactos', id: 9, m: 365 },
        { text: 'Días Aproximados', id: 10, m: 360 },
      ],
      tipos_interes: [
        { text: 'Simple Ordinario', id: 1 },
        { text: 'Exacto', id: 2 },
        { text: 'Bancario', id: 3 },
      ],
      colas: [
        { id: 1, text: 'Cola Derecha' },
        { id: 2, text: 'Cola Izquierda' },
        { id: 3, text: 'Dos Colas' },
      ],
      lista: [0],

      nivelConfianza: 0,
      media: 0,
      desviacionEst: 0,
      n: 0,
      N: 0,

      Z: null,
      errorEstandar: null,
      errorMuestral: null,
      factorCorreccion: null,
      limiteSuperior: null,
      limiteInferior: null,
    }
  },
  methods: {
    async calcular() {
      this.Z = calcularInvNormEstandEstDes(this.nivelConfianza / 100)

      this.errorEstandar = calcularErrorEstandarEnvConfMediaZ(
        this.desviacionEst,
        this.n
      )

      this.errorMuestral = calcularMultiplicacion(this.Z, this.errorEstandar)

      this.factorCorreccion = calcularFactorCorreccionInvConfZMediaFinita(
        this.n,
        this.N
      )

      this.limiteInferior = calcularResta(
        this.media,
        calcularMultiplicacion(this.errorMuestral, this.factorCorreccion)
      )
      this.limiteSuperior = calcularSuma(
        this.media,
        calcularMultiplicacion(this.errorMuestral, this.factorCorreccion)
      )
    },
    addList() {
      this.lista.push(0)
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>

<template>
  <div>
    <div class="box space-card">
      <p class="title">
        Valor futuro
      </p>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-3">
              <b-field label="Descuento" :label-position="labelPosition">
                <b-input type="number" placeholder="12%" v-model="descuento">
                </b-input>
              </b-field>
            </div>
            <div class="column is-6">
              <b-field label="Tiempo" :label-position="labelPosition">
                <b-input type="number" placeholder="9" v-model="periodoN">
                </b-input>
                <b-select
                  placeholder="Selecciona un tipo"
                  expanded
                  v-model="periodo"
                >
                  <option
                    :value="item.id"
                    v-for="(item, index) in periodos"
                    :key="index"
                    >{{ item.text }}</option
                  >
                </b-select>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="Capital" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="capital"
                >
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4">
              <b-button type="is-primary" expanded @click="calcular"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <p class="title is-5">Tiempo: {{ tiempo1 | round }}</p>
            <p class="title is-5">
              Valor de vencimiento: {{ valorVencimiento | round }}
            </p>
            <p class="title is-5">Costo real: {{ costoReal | x100 | round }} %</p>
            <p class="title is-5">Intereses: {{ intereses | round }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  calcularVn,
  calcularInteresesDos,
  calcularNetoPagar,
  calcularDivision,
  calcularValorVencimiento,
  calcularPeriodo,
  calcularCostoReal,
} from '../services/index.js'

export default {
  data() {
    return {
      selected: new Date(),
      showWeekNumber: false,
      locale: undefined,
      labelPosition: 'on-border',
      periodos: [
        { text: 'n/a', id: 0, m: 0 },
        { text: 'Años', id: 1, m: 1 },
        { text: 'Semestres', id: 2, m: 2 },
        { text: 'Cuatrimetres', id: 3, m: 3 },
        { text: 'Trimestres', id: 4, m: 4 },
        { text: 'Bimestres', id: 5, m: 6 },
        { text: 'Meses', id: 6, m: 12 },
        { text: 'Quincenas', id: 7, m: 24 },
        { text: 'Semanas', id: 8, m: 52 },
        { text: 'Días Exactos', id: 9, m: 365 },
        { text: 'Días Aproximados', id: 10, m: 360 },
      ],
      tipos_interes: [
        { text: 'Simple Ordinario', id: 1 },
        { text: 'Exacto', id: 2 },
        { text: 'Bancario', id: 3 },
      ],

      descuento: 0,
      periodoN: 0,
      periodo: 0,
      capital: 0,
      tiempo1: 0,
      valorVencimiento: 0,
      costoReal: 0,
      intereses: 0,
    }
  },
  methods: {
    async calcular() {
      this.tiempo1 = await calcularPeriodo(
        this.periodos.find((p) => p.id == this.periodo).m,
        this.periodoN
      )

      this.valorVencimiento = await calcularValorVencimiento(
        this.capital,
        this.descuento,
        this.tiempo1
      )

      this.costoReal = await calcularCostoReal(
        this.valorVencimiento,
        this.capital,
        this.tiempo1
      )

      this.intereses = await calcularInteresesDos(
        this.valorVencimiento,
        this.capital
      )
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>

<template>
  <div>
    <div class="box space-card">
      <p class="title">
        Cuando se ocupa la tasa de interes y el valor futuro
      </p>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field label="Tasa Interés (i)" :label-position="labelPosition">
                <b-input type="number" placeholder="12,00%" v-model="tasa1">
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Intereses" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor de interes"
                  v-model="intereses1"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Capital (C)" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe una capital"
                  v-model="capital1"
                >
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4">
              <b-field label="Tipo Interés" :label-position="labelPosition">
                <b-select
                  placeholder="Selecciona un tipo"
                  expanded
                  v-model="periodo"
                >
                  <option
                    :value="item.id"
                    v-for="(item, index) in periodos"
                    :key="index"
                    >{{ item.text }}</option
                  >
                </b-select>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Fecha de Inicio" :label-position="labelPosition">
                <b-datepicker
                  v-model="fechaInicial1"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-4">
              <b-button type="is-primary" expanded @click="calcular1"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <p class="title is-5">Tiempo en años (t): {{ tiempo1 | round }}</p>
            <p class="title is-5">Tiempo: {{ periodo1 | round }}</p>
            <p class="title is-5">Fecha final: {{ fechaFinal1 }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  calcularFechaFinal,
  calcularCapitalI,
  calcularTiempoOcupaFuturo,
} from '../services/index.js'

export default {
  data() {
    return {
      selected: new Date(),
      showWeekNumber: false,
      locale: undefined,
      labelPosition: 'on-border',
      periodos: [
        { text: 'n/a', id: 0, m: 0 },
        { text: 'Años', id: 1, m: 1 },
        { text: 'Semestres', id: 2, m: 2 },
        { text: 'Cuatrimetres', id: 3, m: 3 },
        { text: 'Trimestres', id: 4, m: 4 },
        { text: 'Bimestres', id: 5, m: 6 },
        { text: 'Meses', id: 6, m: 12 },
        { text: 'Quincenas', id: 7, m: 24 },
        { text: 'Semanas', id: 8, m: 52 },
        { text: 'Días Exactos', id: 9, m: 365 },
        { text: 'Días Aproximados', id: 10, m: 360 },
      ],
      tipos_interes: [
        { text: 'Simple Ordinario', id: 1 },
        { text: 'Exacto', id: 2 },
        { text: 'Bancario', id: 3 },
      ],

      //1
      tasa1: 0,
      intereses1: 0,
      capital1: 0,
      periodo: 0,
      fechaInicial1: new Date(),
      tiempo1: 0,
      periodo1: 0,
      fechaFinal1: null,
    }
  },
  methods: {
    async calcular1() {
      this.tiempo1 = await calcularCapitalI(
        this.intereses1,
        this.capital1,
        this.tasa1
      )
      this.periodo1 = await calcularTiempoOcupaFuturo(
        this.periodos.find((p) => p.id == this.periodo).m,
        this.tiempo1
      )
      this.fechaFinal1 = await calcularFechaFinal(
        this.fechaInicial1,
        this.tiempo1
      )
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>

<template>
  <div>
    <div class="box space-card">
      <p class="title">
        Teorema Limite Central
      </p>
      <div class="columns">
        <div class="column is-4">
          <img src="img/distcont3.png" alt="" />
        </div>
        <div class="column is-4">
          <img src="img/distcont4.png" alt="" />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-3">
              <b-field label="Promedio" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="promedio"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field
                label="Desviación Estandar"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="desviacionEst"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="Tamaño Muestra" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="muestra"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="Descripción" :label-position="labelPosition">
                <b-select
                  placeholder="Selecciona una cola"
                  expanded
                  v-model="cola"
                >
                  <option :value="0">Menor</option>
                  <option :value="1">Mayor</option>
                </b-select>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column is-3">
              <b-field label="X" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="X"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="X Inferior" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="xi"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="X Superior" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="xs"
                >
                </b-input>
              </b-field>
            </div>

            <div class="column is-3">
              <b-button type="is-primary" expanded @click="calcular"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <hr />
            <p class="title is-5">
              Error Estandar: {{ errorEstandar | round }}
            </p>
            <p class="title is-5">Valor Z: {{ valorz | round }}</p>
            <p class="title is-5">Probabilidad: {{ prob | round4 }}</p>
            <hr />
            <p class="title is-5">
              Error Estandar: {{ errorEstandar2 | round }}
            </p>
            <p class="title is-5">Valor Z Inferior: {{ valorzi | round4 }}</p>
            <p class="title is-5">Valor Z Superior: {{ valorzs | round4 }}</p>
            <p class="title is-5">
              Probabilidad Acumulada Inferior: {{ probai | round4 }}
            </p>
            <p class="title is-5">
              Probabilidad Acumulada Superior: {{ probas | round4 }}
            </p>
            <p class="title is-5">Probabilidad: {{ probT | round4 }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  calcularDivision,
  calcularInteresesDos as calcularResta,
  calcularInvT,
  calcularInvNormEstand,
  calcularZCalculado,
  calcularPromedioArray,
  calcularDesviacionMuestral,
  calcularVarianzaMedias,
  calcularZCalculadoDosMedias,
  calcularPromedioArrayJson,
  calcularVarianzaConjunta,
  calcularTCalculadoDosMedias,
  calcularSuma,
  calcularGradoLibertadDif,
  calcularTCalculadoDosMediasDif,
  calcularInvNormEstandEstDes,
  calcularNEstDes,
  calcularNEstPro,
  calcularErrorEstandarEnvConfMediaZ,
  calcularTiempoOcupaFuturo as calcularMultiplicacion,
  calcularFactorCorreccionInvConfZMediaFinita,
  calcularErrorEstandarEnvConfZProporcion,
  calcularRaiz,
  calcularBinomialList,
  calcularBinomialTablePx,
  calcularBinomialIntervalos,
  calcularHiperGeometrica,
  calcularPoissonList,
  calcularPoisson,
  calcularPoissonIntervalos,
  calcularDistNormal,
  calcularDistNormalEstandar,
  calcularDistNormalInv,
} from '../services/index.js'

export default {
  data() {
    return {
      labelPosition: 'on-border',
      periodos: [
        { text: 'n/a', id: 0, m: 0 },
        { text: 'Años', id: 1, m: 1 },
        { text: 'Semestres', id: 2, m: 2 },
        { text: 'Cuatrimetres', id: 3, m: 3 },
        { text: 'Trimestres', id: 4, m: 4 },
        { text: 'Bimestres', id: 5, m: 6 },
        { text: 'Meses', id: 6, m: 12 },
        { text: 'Quincenas', id: 7, m: 24 },
        { text: 'Semanas', id: 8, m: 52 },
        { text: 'Días Exactos', id: 9, m: 365 },
        { text: 'Días Aproximados', id: 10, m: 360 },
      ],
      tipos_interes: [
        { text: 'Simple Ordinario', id: 1 },
        { text: 'Exacto', id: 2 },
        { text: 'Bancario', id: 3 },
      ],
      colas: [
        { id: 1, text: 'Cola Derecha' },
        { id: 2, text: 'Cola Izquierda' },
        { id: 3, text: 'Dos Colas' },
      ],
      binomials: [
        { text: 'Exactamente x', id: 0, m: 0.387420489 },
        { text: 'Al menos x', id: 1, m: 0.387420489 },
        { text: 'x o más', id: 2, m: 0.387420489 },
        { text: 'A lo más x', id: 3, m: 0.387420489 },
        { text: 'No más de x', id: 4, m: 1 },
        { text: 'Menos de x', id: 5, m: 0.612579511 },
        { text: 'Más de x', id: 6, m: 0 },
        { text: 'A lo sumo x', id: 7, m: 1 },
        { text: 'A lo menos x', id: 8, m: 0.387420489 },
        { text: 'x o menos', id: 9, m: 1 },
        { text: 'Por lo menos x', id: 10, m: 0.387420489 },
        { text: 'Ninguno', id: 11, m: 1e-9 },
        { text: 'Todos', id: 12, m: 0.387420489 },
      ],
      lista: [0],
      datos: [0],
      listProb: [
        {
          x: null,
          fx: null,
          px: null,
          xpx: null,
          xu: null,
          xu2: null,
          xu2px: null,
        },
      ],
      dataBinom: [],
      dataHiperg: [],
      dataPoisson: [],

      nivelConfianza: 0,
      media: 0,
      desviacionEst: 0,
      n: 0,
      p: 0,
      N: 0,
      S: 0,
      px: 0,
      fx: 0,
      varianza: 0,
      x: 0,
      binom: 0,
      i1: null,
      i2: null,
      binomName: null,
      binomValue: null,
      binom1: null,
      binom2: null,
      hiperg: null,
      mediaRes: 0,
      poi1: null,
      poi2: null,
      xi: 0,
      xs: 0,
      cola: 0,
      X: 0,
      muestra: 0,

      Z: null,
      errorEstandar: null,
      errorEstandar2: null,
      errorMuestral: null,
      factorCorreccion: null,
      limiteSuperior: null,
      limiteInferior: null,
      promedio: 0,
      t: null,
      gradoLibertad: null,

      valorz: null,
      prob: null,
      valorzi: null,
      valorzs: null,
      probai: null,
      probas: null,
      probT: null,
    }
  },
  methods: {
    async calcular() {
      this.errorEstandar = calcularDivision(
        this.desviacionEst,
        calcularRaiz(this.muestra)
      )

      this.valorz = calcularDivision(
        calcularResta(this.X, this.promedio),
        this.errorEstandar
      )

      this.prob = calcularDistNormal(
        this.cola,
        this.X,
        this.promedio,
        this.errorEstandar
      )

      this.errorEstandar2 = calcularDivision(
        this.desviacionEst,
        calcularRaiz(this.muestra)
      )

      this.valorzi = calcularDivision(
        calcularResta(this.xi, this.promedio),
        calcularDivision(this.desviacionEst, calcularRaiz(this.muestra))
      )

      this.valorzs = calcularDivision(
        calcularResta(this.xs, this.promedio),
        calcularDivision(this.desviacionEst, calcularRaiz(this.muestra))
      )

      this.probai = calcularDistNormalEstandar(this.valorzi)
      this.probas = calcularDistNormalEstandar(this.valorzs)

      this.probT = calcularResta(this.probas, this.probai)
    },
    addListProb() {
      this.listProb.push({
        x: null,
        fx: null,
        px: null,
        xpx: null,
        xu: null,
        xu2: null,
        xu2px: null,
      })
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>

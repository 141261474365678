<template>
  <div>
    <div class="box space-card">
      <p class="title">
        Hipo 1 Muestra T
      </p>
      <img src="img/hipo1muestrat.png" alt="" />
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field
                label="Media poblacional"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="mediaP"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field
                label="Nivel de significancia"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="nivelSignificancia"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Colas" :label-position="labelPosition">
                <b-select
                  placeholder="Selecciona una cola"
                  expanded
                  v-model="cola"
                >
                  <option
                    :value="item.id"
                    v-for="(item, index) in colas"
                    :key="index"
                    >{{ item.text }}</option
                  >
                </b-select>
              </b-field>
            </div>
          </div>

          <div class="columns" v-for="(item, index) in lista" :key="index">
            <div class="column is-5">
              <p class="title is-5">Nro: {{ index + 1 }}</p>
            </div>
            <div class="column is-5">
              <b-field label="X" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="lista[index]"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-2" v-show="index == lista.length - 1">
              <b-button
                expanded
                icon-left="plus-circle"
                icon-pack="fas"
                type="is-success"
                @click="addList()"
              >
                Añadir
              </b-button>
            </div>
          </div>

          <div class="columns">
            <div class="column is-12">
              <b-button type="is-primary" expanded @click="calcular"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <p class="title is-5">Promedio: {{ promedio | round }}</p>
            <p class="title is-5">
              Desviacion Muestral: {{ desviacionMuestral | round }}
            </p>
            <p class="title is-5">Cantidad: {{ cantidad | round }}</p>
            <p class="title is-5">T Calculado: {{ tCalculado | round }}</p>
            <p class="title is-5">
              Grado Libertad: {{ gradoLibertad | round }}
            </p>
            <p class="title is-5">
              Cola Izquierda: {{ colaI | round }} --> Decisión:
              {{ decisionI }}
            </p>
            <p class="title is-5">
              Cola Derecha: {{ colaD | round }} --> Decisión:
              {{ decisionD }}
            </p>
            <p class="title is-5">
              Dos Colas: {{ dosColas | round }} | {{ dosColas2 | round }} -->
              Decisión:
              {{ decisionDos }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  calcularDivision,
  calcularInteresesDos,
  calcularInvT,
  calcularInvNormEstand,
  calcularZCalculado,
  calcularPromedioArray,
  calcularDesviacionMuestral,
} from '../services/index.js'

export default {
  data() {
    return {
      labelPosition: 'on-border',
      periodos: [
        { text: 'n/a', id: 0, m: 0 },
        { text: 'Años', id: 1, m: 1 },
        { text: 'Semestres', id: 2, m: 2 },
        { text: 'Cuatrimetres', id: 3, m: 3 },
        { text: 'Trimestres', id: 4, m: 4 },
        { text: 'Bimestres', id: 5, m: 6 },
        { text: 'Meses', id: 6, m: 12 },
        { text: 'Quincenas', id: 7, m: 24 },
        { text: 'Semanas', id: 8, m: 52 },
        { text: 'Días Exactos', id: 9, m: 365 },
        { text: 'Días Aproximados', id: 10, m: 360 },
      ],
      tipos_interes: [
        { text: 'Simple Ordinario', id: 1 },
        { text: 'Exacto', id: 2 },
        { text: 'Bancario', id: 3 },
      ],
      colas: [
        { id: 1, text: 'Cola Derecha' },
        { id: 2, text: 'Cola Izquierda' },
        { id: 3, text: 'Dos Colas' },
      ],
      lista: [0],

      mediaP: 0,
      nivelSignificancia: 0,
      cola: null,

      promedio: 0,
      desviacionMuestral: 0,
      cantidad: 0,
      tCalculado: 0,
      gradoLibertad: 0,

      colaI: null,
      decisionI: '',
      colaD: null,
      decisionD: '',
      dosColas: null,
      dosColas2: null,
      decisionDos: '',
    }
  },
  methods: {
    async calcular() {
      this.promedio = calcularPromedioArray(this.lista.filter((i) => i != 0))

      this.desviacionMuestral = calcularDesviacionMuestral(
        this.lista.filter((i) => i != 0)
      )
      this.cantidad = this.lista.length

      this.gradoLibertad = calcularInteresesDos(this.cantidad, 1)

      this.tCalculado = calcularZCalculado(
        this.promedio,
        this.desviacionMuestral,
        this.cantidad,
        this.mediaP
      )

      this.colaI = 0
      this.colaD = 0
      this.dosColas = 0
      this.dosColas2 = 0
      this.decisionI = ''
      this.decisionD = ''
      this.decisionDos = ''

      if (this.cola == 1) {
        this.colaD = calcularInvT(
          this.cola,
          this.nivelSignificancia,
          this.gradoLibertad
        )
        this.decisionD =
          this.tCalculado > this.colaD ? 'Se rechaza Ho' : 'Se acepta Ho'
      } else if (this.cola == 2) {
        this.colaI = calcularInvT(
          this.cola,
          this.nivelSignificancia,
          this.gradoLibertad
        )
        this.decisionI =
          this.tCalculado < this.colaI ? 'Se rechaza Ho' : 'Se acepta Ho'
      } else {
        this.dosColas = calcularInvT(
          3,
          this.nivelSignificancia,
          this.gradoLibertad
        )
        this.dosColas2 = calcularInvT(
          4,
          this.nivelSignificancia,
          this.gradoLibertad
        )
        this.decisionDos =
          Math.abs(this.tCalculado) > Math.abs(this.dosColas)
            ? 'Se rechaza Ho'
            : 'Se acepta Ho'
      }
    },
    addList() {
      this.lista.push(0)
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>

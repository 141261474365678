<template>
  <div>
    <div class="box space-card">
      <p class="title">
        Dist. Poisson
      </p>
      <!-- <div class="columns">
        <div class="column is-4">
          <img src="img/prob3.png" alt="" />
        </div>
        <div class="column is-4">
          <img src="img/prob4.png" alt="" />
        </div>
      </div> -->
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field label="Media" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="media"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="x" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="x"
                >
                </b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column is-4">
              <b-field label="Descripción" :label-position="labelPosition">
                <b-select
                  placeholder="Selecciona una cola"
                  expanded
                  v-model="binom"
                >
                  <option
                    :value="item.id"
                    v-for="(item, index) in binomials"
                    :key="index"
                    >{{ item.text }}</option
                  >
                </b-select>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Intervalos" :label-position="labelPosition">
                <b-input type="number" placeholder="de" v-model="i1"> </b-input>
                <b-input type="number" placeholder="a" v-model="i2"> </b-input>
              </b-field>
            </div>

            <div class="column is-4">
              <b-button type="is-primary" expanded @click="calcular"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <!-- <p class="title is-5">n: {{ n | round }}</p>
            <p class="title is-5">Total F(x): {{ fx | round }}</p>
            <p class="title is-5">Total P(x): {{ px | round }}</p> -->
            <hr />
            <p class="title is-5">Media: {{ mediaRes | round }}</p>
            <!-- <p class="title is-5">Varianza: {{ varianza | round }}</p>
            <p class="title is-5">
              Desviación Estandar: {{ desviacionEst | round }}
            </p> -->
            <!-- <p class="title is-5">
              Probabilidad de {{ binomName }}: {{ binom1 | round3 }}
            </p>
            <p class="title is-5">
              Probabilidad de {{ i1 }} a {{ i2 }}: {{ binom2 | round3 }}
            </p> -->

            <p class="title is-5">
              Probabilidad de {{ binomName }}: {{ poi1 | round4 }}
            </p>
            <p class="title is-5">
              Probabilidad de {{ i1 }} a {{ i2 }}: {{ poi2 | round4 }}
            </p>

            <hr />

            <b-table
              :data="dataPoisson"
              :bordered="true"
              :striped="true"
              :narrowed="true"
              :hoverable="true"
            >
              <b-table-column
                field="dis"
                label="Distribución"
                width="100"
                numeric
                v-slot="props"
              >
                {{ props.row.nro }}
              </b-table-column>

              <b-table-column field="px" label="P(x)" v-slot="props">
                {{ props.row.px }}
              </b-table-column>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  calcularDivision,
  calcularInteresesDos as calcularResta,
  calcularInvT,
  calcularInvNormEstand,
  calcularZCalculado,
  calcularPromedioArray,
  calcularDesviacionMuestral,
  calcularVarianzaMedias,
  calcularZCalculadoDosMedias,
  calcularPromedioArrayJson,
  calcularVarianzaConjunta,
  calcularTCalculadoDosMedias,
  calcularSuma,
  calcularGradoLibertadDif,
  calcularTCalculadoDosMediasDif,
  calcularInvNormEstandEstDes,
  calcularNEstDes,
  calcularNEstPro,
  calcularErrorEstandarEnvConfMediaZ,
  calcularTiempoOcupaFuturo as calcularMultiplicacion,
  calcularFactorCorreccionInvConfZMediaFinita,
  calcularErrorEstandarEnvConfZProporcion,
  calcularRaiz,
  calcularBinomialList,
  calcularBinomialTablePx,
  calcularBinomialIntervalos,
  calcularHiperGeometrica,
  calcularPoissonList,
  calcularPoisson,
  calcularPoissonIntervalos,
} from '../services/index.js'

export default {
  data() {
    return {
      labelPosition: 'on-border',
      periodos: [
        { text: 'n/a', id: 0, m: 0 },
        { text: 'Años', id: 1, m: 1 },
        { text: 'Semestres', id: 2, m: 2 },
        { text: 'Cuatrimetres', id: 3, m: 3 },
        { text: 'Trimestres', id: 4, m: 4 },
        { text: 'Bimestres', id: 5, m: 6 },
        { text: 'Meses', id: 6, m: 12 },
        { text: 'Quincenas', id: 7, m: 24 },
        { text: 'Semanas', id: 8, m: 52 },
        { text: 'Días Exactos', id: 9, m: 365 },
        { text: 'Días Aproximados', id: 10, m: 360 },
      ],
      tipos_interes: [
        { text: 'Simple Ordinario', id: 1 },
        { text: 'Exacto', id: 2 },
        { text: 'Bancario', id: 3 },
      ],
      colas: [
        { id: 1, text: 'Cola Derecha' },
        { id: 2, text: 'Cola Izquierda' },
        { id: 3, text: 'Dos Colas' },
      ],
      binomials: [
        { text: 'Exactamente x', id: 0, m: 0.387420489 },
        { text: 'Al menos x', id: 1, m: 0.387420489 },
        { text: 'x o más', id: 2, m: 0.387420489 },
        { text: 'A lo más x', id: 3, m: 0.387420489 },
        { text: 'No más de x', id: 4, m: 1 },
        { text: 'Menos de x', id: 5, m: 0.612579511 },
        { text: 'Más de x', id: 6, m: 0 },
        { text: 'A lo sumo x', id: 7, m: 1 },
        { text: 'A lo menos x', id: 8, m: 0.387420489 },
        { text: 'x o menos', id: 9, m: 1 },
        { text: 'Por lo menos x', id: 10, m: 0.387420489 },
        { text: 'Ninguno', id: 11, m: 1e-9 },
        { text: 'Todos', id: 12, m: 0.387420489 },
      ],
      lista: [0],
      datos: [0],
      listProb: [
        {
          x: null,
          fx: null,
          px: null,
          xpx: null,
          xu: null,
          xu2: null,
          xu2px: null,
        },
      ],
      dataBinom: [],
      dataHiperg: [],
      dataPoisson: [],

      nivelConfianza: 0,
      media: 0,
      desviacionEst: null,
      n: 0,
      p: 0,
      N: 0,
      S: 0,
      px: 0,
      fx: 0,
      varianza: 0,
      x: 0,
      prob: 0,
      binom: 0,
      i1: null,
      i2: null,
      binomName: null,
      binomValue: null,
      binom1: null,
      binom2: null,
      hiperg: null,
      mediaRes: 0,
      poi1: null,
      poi2: null,

      Z: null,
      errorEstandar: null,
      errorMuestral: null,
      factorCorreccion: null,
      limiteSuperior: null,
      limiteInferior: null,
      promedio: null,
      t: null,
      gradoLibertad: null,
    }
  },
  methods: {
    async calcular() {
      for (let i = 0; i < 21; i++) {
        let tmp = {
          nro: i,
          px: calcularPoisson(i, this.media),
        }
        // console.log(tmp)
        this.dataPoisson.push(tmp)
      }

      this.mediaRes = [...this.dataPoisson]
        .map((i) => i.px)
        .reduce((a, b) => a + b)

      this.binomName = this.binomials.find((i) => i.id == this.binom).text

      this.poi1 = calcularPoissonList(this.binom, this.x, this.media)
      this.poi2 = calcularPoissonIntervalos(this.i1, this.i2, this.media)
    },
    addListProb() {
      this.listProb.push({
        x: null,
        fx: null,
        px: null,
        xpx: null,
        xu: null,
        xu2: null,
        xu2px: null,
      })
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>

<template>
  <div>
    <div class="box space-card">
      <p class="title">
        Dist. Hispergeometrica
      </p>
      <!-- <div class="columns">
        <div class="column is-4">
          <img src="img/prob3.png" alt="" />
        </div>
        <div class="column is-4">
          <img src="img/prob4.png" alt="" />
        </div>
      </div> -->
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-3">
              <b-field label="N" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="N"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="S" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="S"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="n" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="n"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label="x" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="x"
                >
                </b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <!-- <div class="column is-4">
              <b-field label="Descripción" :label-position="labelPosition">
                <b-select
                  placeholder="Selecciona una cola"
                  expanded
                  v-model="binom"
                >
                  <option
                    :value="item.id"
                    v-for="(item, index) in binomials"
                    :key="index"
                    >{{ item.text }}</option
                  >
                </b-select>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Intervalos" :label-position="labelPosition">
                <b-input type="number" placeholder="de" v-model="i1"> </b-input>
                <b-input type="number" placeholder="a" v-model="i2"> </b-input>
              </b-field>
            </div> -->

            <div class="column is-12">
              <b-button type="is-primary" expanded @click="calcular"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <!-- <p class="title is-5">n: {{ n | round }}</p>
            <p class="title is-5">Total F(x): {{ fx | round }}</p>
            <p class="title is-5">Total P(x): {{ px | round }}</p> -->
            <hr />
            <!-- <p class="title is-5">Media: {{ media | round }}</p>
            <p class="title is-5">Varianza: {{ varianza | round }}</p>
            <p class="title is-5">
              Desviación Estandar: {{ desviacionEst | round }}
            </p> -->
            <!-- <p class="title is-5">
              Probabilidad de {{ binomName }}: {{ binom1 | round3 }}
            </p>
            <p class="title is-5">
              Probabilidad de {{ i1 }} a {{ i2 }}: {{ binom2 | round3 }}
            </p> -->

            <p class="title is-5">Probabilidad: {{ hiperg | round4 }}</p>

            <hr />

            <b-table
              :data="dataHiperg"
              :bordered="true"
              :striped="true"
              :narrowed="true"
              :hoverable="true"
            >
              <b-table-column
                field="dis"
                label="Distribución"
                width="100"
                numeric
                v-slot="props"
              >
                {{ props.row.nro }}
              </b-table-column>

              <b-table-column field="px" label="P(x)" v-slot="props">
                {{ props.row.px }}
              </b-table-column>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  calcularDivision,
  calcularInteresesDos as calcularResta,
  calcularInvT,
  calcularInvNormEstand,
  calcularZCalculado,
  calcularPromedioArray,
  calcularDesviacionMuestral,
  calcularVarianzaMedias,
  calcularZCalculadoDosMedias,
  calcularPromedioArrayJson,
  calcularVarianzaConjunta,
  calcularTCalculadoDosMedias,
  calcularSuma,
  calcularGradoLibertadDif,
  calcularTCalculadoDosMediasDif,
  calcularInvNormEstandEstDes,
  calcularNEstDes,
  calcularNEstPro,
  calcularErrorEstandarEnvConfMediaZ,
  calcularTiempoOcupaFuturo as calcularMultiplicacion,
  calcularFactorCorreccionInvConfZMediaFinita,
  calcularErrorEstandarEnvConfZProporcion,
  calcularRaiz,
  calcularBinomialList,
  calcularBinomialTablePx,
  calcularBinomialIntervalos,
  calcularHiperGeometrica,
} from '../services/index.js'

export default {
  data() {
    return {
      labelPosition: 'on-border',
      periodos: [
        { text: 'n/a', id: 0, m: 0 },
        { text: 'Años', id: 1, m: 1 },
        { text: 'Semestres', id: 2, m: 2 },
        { text: 'Cuatrimetres', id: 3, m: 3 },
        { text: 'Trimestres', id: 4, m: 4 },
        { text: 'Bimestres', id: 5, m: 6 },
        { text: 'Meses', id: 6, m: 12 },
        { text: 'Quincenas', id: 7, m: 24 },
        { text: 'Semanas', id: 8, m: 52 },
        { text: 'Días Exactos', id: 9, m: 365 },
        { text: 'Días Aproximados', id: 10, m: 360 },
      ],
      tipos_interes: [
        { text: 'Simple Ordinario', id: 1 },
        { text: 'Exacto', id: 2 },
        { text: 'Bancario', id: 3 },
      ],
      colas: [
        { id: 1, text: 'Cola Derecha' },
        { id: 2, text: 'Cola Izquierda' },
        { id: 3, text: 'Dos Colas' },
      ],
      binomials: [
        { text: 'Exactamente 9', id: 0, m: 0.387420489 },
        { text: 'Al menos 9', id: 1, m: 0.387420489 },
        { text: '9 o más', id: 2, m: 0.387420489 },
        { text: 'A lo más 9', id: 3, m: 0.387420489 },
        { text: 'No más de 9', id: 4, m: 1 },
        { text: 'Menos de 9', id: 5, m: 0.612579511 },
        { text: 'Más de 9', id: 6, m: 0 },
        { text: 'A lo sumo 9', id: 7, m: 1 },
        { text: 'A lo menos 9', id: 8, m: 0.387420489 },
        { text: '9 o menos', id: 9, m: 1 },
        { text: 'Por lo menos 9', id: 10, m: 0.387420489 },
        { text: 'Ninguno', id: 11, m: 1e-9 },
        { text: 'Todos', id: 12, m: 0.387420489 },
      ],
      lista: [0],
      datos: [0],
      listProb: [
        {
          x: null,
          fx: null,
          px: null,
          xpx: null,
          xu: null,
          xu2: null,
          xu2px: null,
        },
      ],
      dataBinom: [],
      dataHiperg: [],

      nivelConfianza: 0,
      media: 0,
      desviacionEst: null,
      n: 0,
      p: 0,
      N: 0,
      S: 0,
      px: 0,
      fx: 0,
      varianza: 0,
      x: 0,
      prob: 0,
      binom: 0,
      i1: null,
      i2: null,
      binomName: null,
      binomValue: null,
      binom1: null,
      binom2: null,
      hiperg: null,

      Z: null,
      errorEstandar: null,
      errorMuestral: null,
      factorCorreccion: null,
      limiteSuperior: null,
      limiteInferior: null,
      promedio: null,
      t: null,
      gradoLibertad: null,
    }
  },
  methods: {
    async calcular() {
      for (let i = 0; i < 21; i++) {
        if (!(i >= this.n)) {
          let tmp = {
            nro: i,
            px: calcularHiperGeometrica(i, this.N, this.n, this.S),
          }
          // console.log(tmp)
          this.dataHiperg.push(tmp)
        }
      }

      // this.media = [...this.dataBinom].map((i) => i.px).reduce((a, b) => a + b)

      this.hiperg = calcularHiperGeometrica(this.x, this.N, this.n, this.S)
      // this.binomName = this.binomials.find((i) => i.id == this.binom).text

      // this.binom2 = calcularBinomialIntervalos(this.i1, this.i2, this.n, this.p)
    },
    addListProb() {
      this.listProb.push({
        x: null,
        fx: null,
        px: null,
        xpx: null,
        xu: null,
        xu2: null,
        xu2px: null,
      })
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>

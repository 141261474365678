<template>
  <div>
    <div class="box space-card">
      <p class="title">APROXIMADO</p>
      <p class="subtitle">Funcion Dias360</p>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field label="Fecha de Inicio" :label-position="labelPosition">
                <b-datepicker
                  v-model="efd_date_start"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Fecha de Inicio" :label-position="labelPosition">
                <b-datepicker
                  v-model="efd_date_end"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-4">
              <b-button
                type="is-primary"
                expanded
                @click="calcularFuncionDias()"
                >CALCULAR</b-button
              >
            </div>
          </div>
          <div>
            <p class="subtitle">Días aproximados:</p>
            {{ resultado_efd | round }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { calcularDias360 } from '../services/index.js'

export default {
  data() {
    return {
      selected: new Date(),
      showWeekNumber: false,
      locale: undefined,
      labelPosition: 'on-border',

      efd_date_start: new Date(),
      efd_date_end: new Date(),
      resultado_efd: 0,
    }
  },
  methods: {
    calcularFuncionDias() {
      this.resultado_efd = calcularDias360(
        this.efd_date_start,
        this.efd_date_end
      )
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>

/**
 *
 */

import moment from 'moment'
import jsstats from 'js-stats'
import jStat from 'jstat'

const calcularDias = (dayStart, dayEnd) => {
  return Math.abs(moment(dayStart).diff(moment(dayEnd), 'days'))
}

const calcularDias360 = (dayStart, dayEnd) => {
  return (
    Math.abs(moment(dayStart).diff(moment(dayEnd), 'month')) * 30 +
    moment(dayEnd).get('date') -
    moment(dayStart).get('date')
  )
}

const sumarDias = (dayStart, days) => {
  return moment(dayStart)
    .add(days, 'days')
    .format('L')
}

const calcularTiempo = (type, dayStart, dayEnd) => {
  return type == 1
    ? calcularDias360(dayStart, dayEnd) / 360
    : type == 2
    ? calcularDias(dayStart, dayEnd) / 365
    : type == 3
    ? calcularDias(dayStart, dayEnd) / 360
    : 0
}

const calcularPeriodo = (type, valor) => {
  return type == 0 ? 0 : valor / type
}

const calcularIntereses = (capital, tiempo, interes) => {
  return capital * tiempo * (interes / 100)
}

const calcularInteresesDos = (a, b) => {
  return a - b
}

const calcularValorFuturo = (capital, interes, tiempo) => {
  return capital * (1 + tiempo * (interes / 100))
}

const calcularValorPresente = (futuro, interes, tiempo) => {
  return futuro / (1 + tiempo * (interes / 100))
}

const calcularFechaFinal = (dayStart, periodo) => {
  return moment(dayStart)
    .add(periodo * 365, 'days')
    .format('L')
}

const calcularTasaInteres = (a, b, c) => {
  return (a / (b * c)) * 100
  // return Math.round(res * 100) / 100
}
const calcularTiempoOcupa = (a, b, interes) => {
  return (a - b) / (b * (interes / 100))
  // return Math.round(res * 100) / 100
}

const calcularTiempoOcupaFuturo = (a, b) => {
  return a * b
}

const calcularValorFuturoI = (a, b) => {
  return parseFloat(a) + parseFloat(b)
}

const calcularCapitalI = (a, b, c) => {
  let bc = b * (c / 100)
  return a / bc
  // return Math.round(res * 100) / 100
}

const calcularVn = (a, b) => {
  return a * (1 - b / 100)
  // return Math.round(res * 100) / 100
}

const calcularNetoPagar = (a, b, c, d) => {
  return a * (1 - b / 100) * (1 - c / 100) * (1 - d / 100)
  // return Math.round(res * 100) / 100
}

const calcularDivision = (a, b) => {
  return a / b
}

const calcularTiempoDescuento = (dayStart, dayEnd) => {
  return calcularDias(dayStart, dayEnd) / 360
}

const calcularCantNetaRecibida = (valor, tasa, tiempo) => {
  return valor * (1 - tiempo * (tasa / 100))
}

const calcularCantRecibir = (a, tasa, c) => {
  return a * (1 + (tasa / 100) * (c / 360))
  // return Math.round(res * 100) / 100
}

const calcularRendimientoRelativo = (a, b, tiempo) => {
  return a / (b * (tiempo / 360))
  // return Math.round(res * 100) / 100
}

const calcularValorDescontado = (a, tasa, c) => {
  return a * (1 - (tasa / 100) * (c / 360))
  // return Math.round(res * 100) / 100
}

const calcularMontoDescontado = (a, b, c) => {
  let bb = b / 100
  let cc = c / 360
  return a * bb * cc
  // return Math.round(res * 100) / 100
}

const calcularValorVencimiento = (a, tasa, tiempo) => {
  return a / (1 - (tasa / 100) * tiempo)
  // return Math.round(res * 100) / 100
}

const calcularCostoReal = (a, b, c) => {
  return (a - b) / (b * c)
  // return Math.round(res * 100) / 100
}
const calcularTasaDescuentoUnico = (a, b) => {
  return (a - b) / a
  // return Math.round(res * 100) / 100
}

const calcularMontoPago = (monto, tasa, deuda, periodo) => {
  return monto * (1 + (tasa / 100) * (deuda - periodo))
  // return Math.round(res * 100) / 100
}

const calcularValorDeudada = (deuda, tasa, periodo) => {
  return deuda * (1 + (tasa / 100) * periodo)
  // return Math.round(res * 100) / 100
}

const calcularSuma = (a, b) => {
  return parseFloat(a) + parseFloat(b)
  // return Math.round(res * 100) / 100
}

const calcularPagoMensual = (a, b, tiempo) => {
  return (parseFloat(a) + parseFloat(b)) / (tiempo * 12)
  // return Math.round(res * 100) / 100
}

const calcularInvT = (cola, a, b) => {
  let tDistribution = new jsstats.TDistribution(b)
  return cola == 2
    ? tDistribution.invCumulativeProbability(a)
    : cola == 1
    ? tDistribution.invCumulativeProbability(1 - a)
    : cola == 3
    ? tDistribution.invCumulativeProbability(a / 2)
    : tDistribution.invCumulativeProbability(1 - a / 2)
}

const calcularInvNormEstand = (cola, a) => {
  let normalDistribution = new jsstats.NormalDistribution(0, 1)
  return cola == 2
    ? normalDistribution.invCumulativeProbability(a)
    : cola == 1
    ? normalDistribution.invCumulativeProbability(1 - a)
    : cola == 3
    ? normalDistribution.invCumulativeProbability(a / 2)
    : normalDistribution.invCumulativeProbability(1 - a / 2)
}

const calcularZCalculado = (a, b, c, d) => {
  return (d - a) / (b / Math.sqrt(c))
}

const calcularPromedioArray = (arr) => {
  return (
    [...arr].reduce(
      (acum, value) => (acum = parseFloat(acum) + parseFloat(value))
    ) / arr.length
  )
}

const calcularDesviacionMuestral = (arr) => {
  let u = calcularPromedioArray(arr)
  let X = 0
  let n = arr.length - 1
  for (let i of arr) X += Math.pow(Math.abs(i - u), 2)
  return Math.sqrt(X / n)
}

const calcularVarianzaMedias = (a1, b1, a2, b2) => {
  return a1 * (a1 / b1) + a2 * (a2 / b2)
}

const calcularZCalculadoDosMedias = (a1, a2, b) => {
  return (a1 - a2) / Math.sqrt(b)
}

const calcularPromedioArrayJson = (arr, op) => {
  return op == 1
    ? [...arr].reduce(
        (acum, value) => (acum.a = parseFloat(acum.a) + parseFloat(value.a))
      ) / arr.length
    : [...arr].reduce(
        (acum, value) => (acum.b = parseFloat(acum.b) + parseFloat(value.b))
      ) / arr.length
}

const calcularVarianzaConjunta = (a1, b1, a2, b2) => {
  let aa = (b1 - 1) * a1 * a1
  let bb = (b2 - 1) * a2 * a2
  let cc = parseFloat(b1) + parseFloat(b2) - 2
  return (aa + bb) / cc
}

const calcularTCalculadoDosMedias = (a1, a2, b, c1, c2) => {
  return (a1 - a2) / Math.sqrt(b * (1 / c1 + 1 / c2))
}
const calcularTCalculadoDosMediasDif = (a1, a2, a3, b1, b2, b3) => {
  return (a1 - b1) / Math.sqrt(a2 * (a2 / a3) + b2 * (b2 / b3))
}
const calcularGradoLibertadDif = (a1, a2, b1, b2) => {
  let aa = Math.pow(a1 * (a1 / a2) + b1 * (b1 / b2), 2)
  let bb = Math.pow(a1 * (a1 / a2), 2) / (a2 - 1)
  let cc = Math.pow(b1 * (b1 / b2), 2) / (b2 - 1)

  return aa / (bb + cc)
}

const calcularSd = (a, b) => {
  return Math.sqrt(a / (b - 1))
}

const calcularTCalculadoDosMediasDependientes = (a, b, c) => {
  return b / (c / Math.sqrt(a))
}

const calcularInvNormEstandEstDes = (a) => {
  let normalDistribution = new jsstats.NormalDistribution(0, 1)
  let res = normalDistribution.invCumulativeProbability(a + (1 - a) / 2)
  return Math.round(res * 100) / 100
}

const calcularNEstDes = (a, b, c) => {
  return Math.pow((c * b) / a, 2)
}

const calcularNEstPro = (a, b, c) => {
  let aa = parseFloat(c) / parseFloat(a)
  console.log(aa, Math.pow(aa, 2), b, 1 - b)

  return Math.pow(aa, 2) * b * (1 - b)
}

const calcularErrorEstandarEnvConfMediaZ = (a, b) => {
  return a / Math.sqrt(b)
}

const calcularFactorCorreccionInvConfZMediaFinita = (a, b) => {
  return Math.sqrt((b - a) / (b - 1))
}

const calcularErrorEstandarEnvConfZProporcion = (a, b) => {
  return Math.sqrt(a * ((1 - a) / b))
}

const calcularRaiz = (a) => {
  return Math.sqrt(a)
}

const calcularBinomialList = (op, x, n, p) => {
  let res = 0

  x = parseFloat(x)
  n = parseFloat(n)
  p = parseFloat(p)

  if (op == 0) res = jStat.binomial.pdf(x, n, p)
  if (op == 1 || op == 2 || op == 3 || op == 8 || op == 10)
    res = 1 - jStat.binomial.cdf(x - 1, n, p)
  if (op == 4 || op == 7 || op == 9) res = jStat.binomial.cdf(x, n, p)
  if (op == 5) res = jStat.binomial.cdf(x - 1, n, p)
  if (op == 6) res = 1 - jStat.binomial.cdf(x, n, p)
  if (op == 11) res = jStat.binomial.pdf(0, n, p)
  if (op == 12) res = jStat.binomial.pdf(n, n, p)
  return parseFloat(res.toFixed(4))
}

const calcularBinomialTablePx = (x, n, p) => {
  x = parseFloat(x)
  n = parseFloat(n)
  p = parseFloat(p)
  return parseFloat(jStat.binomial.pdf(x, n, p).toFixed(4))
}

const calcularBinomialIntervalos = (x1, x2, n, p) => {
  x1 = parseFloat(x1)
  x2 = parseFloat(x2)
  n = parseFloat(n)
  p = parseFloat(p)
  return parseFloat(
    (jStat.binomial.cdf(x2, n, p) - jStat.binomial.cdf(x1 - 1, n, p)).toFixed(4)
  )
}

const calcularHiperGeometrica = (x, N, n, S) => {
  x = parseFloat(x)
  N = parseFloat(N)
  n = parseFloat(n)
  S = parseFloat(S)
  return parseFloat(jStat.hypgeom.pdf(x, N, n, S).toFixed(4))
}

const calcularPoissonList = (op, x, m) => {
  let res = 0

  x = parseFloat(x)
  m = parseFloat(m)

  if (op == 0 || op == 11 || op == 12) res = jStat.poisson.pdf(x, m)
  if (op == 1 || op == 2 || op == 3 || op == 8 || op == 10)
    res = 1 - jStat.poisson.cdf(x - 1, m)
  if (op == 4 || op == 7 || op == 9) res = jStat.poisson.cdf(x, m)
  if (op == 5) res = jStat.poisson.cdf(x - 1, m)
  if (op == 6) res = 1 - jStat.poisson.cdf(x, m)
  return parseFloat(res.toFixed(4))
}

const calcularPoisson = (x, m) => {
  x = parseFloat(x)
  m = parseFloat(m)
  return parseFloat(jStat.poisson.pdf(x, m).toFixed(4))
}

const calcularPoissonIntervalos = (x1, x2, m) => {
  x1 = parseFloat(x1)
  x2 = parseFloat(x2)
  m = parseFloat(m)
  return parseFloat(
    (jStat.poisson.cdf(x2, m) - jStat.poisson.cdf(x1 - 1, m)).toFixed(4)
  )
}

const calcularDistNormal = (op, x, p, d) => {
  x = parseFloat(x)
  p = parseFloat(p)
  d = parseFloat(d)
  if (op == 0) return parseFloat(jStat.normal.cdf(x, p, d).toFixed(4))
  else return parseFloat(1 - jStat.normal.cdf(x, p, d).toFixed(4))
}

const calcularDistNormalEstandar = (x) => {
  x = parseFloat(x)
  let normal_distribution = new jsstats.NormalDistribution(0, 1)
  return normal_distribution.cumulativeProbability(x).toFixed(4)
}

const calcularDistNormalInv = (op, x, p, d) => {
  x = parseFloat(x)
  p = parseFloat(p)
  d = parseFloat(d)
  if (op == 0) return Math.abs(parseFloat(jStat.normal.inv(x, p, d).toFixed(4)))
  else return Math.abs(parseFloat(jStat.normal.inv(1 - x, p, d).toFixed(4)))
}

export {
  calcularDias,
  sumarDias,
  calcularTiempo,
  calcularIntereses,
  calcularValorFuturo,
  calcularPeriodo,
  calcularFechaFinal,
  calcularInteresesDos,
  calcularValorPresente,
  calcularDias360,
  calcularTasaInteres,
  calcularTiempoOcupa,
  calcularTiempoOcupaFuturo,
  calcularValorFuturoI,
  calcularCapitalI,
  calcularVn,
  calcularNetoPagar,
  calcularDivision,
  calcularTiempoDescuento,
  calcularCantNetaRecibida,
  calcularCantRecibir,
  calcularRendimientoRelativo,
  calcularValorDescontado,
  calcularMontoDescontado,
  calcularValorVencimiento,
  calcularCostoReal,
  calcularTasaDescuentoUnico,
  calcularMontoPago,
  calcularValorDeudada,
  calcularSuma,
  calcularPagoMensual,
  calcularInvT,
  calcularInvNormEstand,
  calcularZCalculado,
  calcularPromedioArray,
  calcularDesviacionMuestral,
  calcularVarianzaMedias,
  calcularZCalculadoDosMedias,
  calcularPromedioArrayJson,
  calcularVarianzaConjunta,
  calcularTCalculadoDosMedias,
  calcularTCalculadoDosMediasDif,
  calcularGradoLibertadDif,
  calcularSd,
  calcularTCalculadoDosMediasDependientes,
  calcularInvNormEstandEstDes,
  calcularNEstDes,
  calcularNEstPro,
  calcularErrorEstandarEnvConfMediaZ,
  calcularFactorCorreccionInvConfZMediaFinita,
  calcularErrorEstandarEnvConfZProporcion,
  calcularRaiz,
  calcularBinomialList,
  calcularBinomialTablePx,
  calcularBinomialIntervalos,
  calcularHiperGeometrica,
  calcularPoisson,
  calcularPoissonList,
  calcularPoissonIntervalos,
  calcularDistNormal,
  calcularDistNormalEstandar,
  calcularDistNormalInv
}

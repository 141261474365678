<template>
  <div>
    <div class="box space-card">
      <p class="title">
        Cuando se ocupa cantidad neta recibida y descuento
      </p>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field
                label="Valor de Vencimiento"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="valorVencimiento1"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field
                label="Tasa de Descuento"
                :label-position="labelPosition"
              >
                <b-input type="number" placeholder="12%" v-model="tasa1">
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Fecha de Inicio" :label-position="labelPosition">
                <b-datepicker
                  v-model="fechaInicial1"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4">
              <b-field label="Fecha de Inicio" :label-position="labelPosition">
                <b-datepicker
                  v-model="fechaFinal1"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-4">
              <b-button type="is-primary" expanded @click="calcular1"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <p class="title is-5">Tiempo: {{ tiempo1 | round }}</p>
            <p class="title is-5">
              Cantidad neta recibida: {{ cantNeta1 | round }}
            </p>
            <p class="title is-5">Descuento: {{ descuento1 | round }}</p>
          </div>
        </div>
      </div>

      <br />
      <hr />
      <br />

      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-3">
              <b-field label="Valor nominal" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="valorVencimiento2"
                >
                </b-input>
              </b-field>
            </div>

            <div class="column is-3">
              <b-field
                label="Tasa de Descuento"
                :label-position="labelPosition"
              >
                <b-input type="number" placeholder="12%" v-model="tasa2">
                </b-input>
              </b-field>
            </div>

            <div class="column is-6">
              <b-field label="Tiempo" :label-position="labelPosition">
                <b-input type="number" placeholder="9" v-model="periodoN">
                </b-input>
                <b-select
                  placeholder="Selecciona un tipo"
                  expanded
                  v-model="periodo"
                >
                  <option
                    :value="item.id"
                    v-for="(item, index) in periodos"
                    :key="index"
                    >{{ item.text }}</option
                  >
                </b-select>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column is-4">
              <b-button type="is-primary" expanded @click="calcular2"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <p class="title is-5">Tiempo: {{ tiempo2 | round }}</p>
            <p class="title is-5">
              Cantidad neta recibida: {{ cantNeta2 | round }}
            </p>
            <p class="title is-5">Descuento: {{ descuento2 | round }}</p>
          </div>
        </div>
      </div>

      <br />
      <hr />
      <br />

      <p class="title">
        Acción de descontar un documento solo un banco
      </p>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field label="Prestamo" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="prestamo1"
                >
                </b-input>
              </b-field>
            </div>

            <div class="column is-4">
              <b-field
                label="Fecha de Inicio Pagaré"
                :label-position="labelPosition"
              >
                <b-datepicker
                  v-model="fechaInicial3"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>

            <div class="column is-4">
              <b-field
                label="Fecha de Fin Pagaré"
                :label-position="labelPosition"
              >
                <b-datepicker
                  v-model="fechaFinal3"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column is-4">
              <b-field
                label="Fecha a Descontar"
                :label-position="labelPosition"
              >
                <b-datepicker
                  v-model="fechaDes1"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field
                label="Tasa de Interés Pagaré"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="tasa3"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field
                label="Tasa de Rendimiento"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="tasaR3"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-2">
              <b-button type="is-primary" expanded @click="calcular3"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <p class="title is-5">
              Días vencimiento pagaré: {{ diasVenPa | round }}
            </p>
            <p class="title is-5">Dia descontar: {{ diasDes | round }}</p>
            <p class="title is-5">
              Dia inicio a fecha descontar: {{ diasIniDes | round }}
            </p>
            <p class="title is-5">Cantidad a recibir: {{ cantRec | round }}</p>
            <p class="title is-5">
              Cantidad que recibirá: {{ cantRecTo | round }}
            </p>
            <p class="title is-5">
              Ganancia en terminos absoluto: {{ gananciaTA | round }}
            </p>
            <p class="title is-5">
              Rendimiento relativo: {{ rendimientoRelativo | x100 | round }} %
            </p>
            <p class="title is-5">
              Costo descontar: {{ costoDes | abs | round }}
            </p>
            <p class="title is-5">Interés: {{ interes3 | x100 | round }} %</p>
          </div>
        </div>
      </div>

      <br />
      <hr />
      <br />

      <p class="title">
        Acción de descontar por dos bancos
      </p>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field label="Prestamo" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="prestamo2"
                >
                </b-input>
              </b-field>
            </div>

            <div class="column is-4">
              <b-field
                label="Fecha de Inicio Pagaré"
                :label-position="labelPosition"
              >
                <b-datepicker
                  v-model="fechaInicial4"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>

            <div class="column is-4">
              <b-field
                label="Fecha de Fin Pagaré"
                :label-position="labelPosition"
              >
                <b-datepicker
                  v-model="fechaFinal4"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column is-4">
              <b-field
                label="Fecha a Descontar"
                :label-position="labelPosition"
              >
                <b-datepicker
                  v-model="fechaDes2"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field
                label="Fecha Redescuesta"
                :label-position="labelPosition"
              >
                <b-datepicker
                  v-model="fechaRedes1"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field
                label="Tasa de Interés Pagaré"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="tasa4"
                >
                </b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column is-4">
              <b-field
                label="Tasa de rendimiento Banco A"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="bancoA1"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field
                label="Tasa de rendimiento Banco B"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="bancoB1"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-button type="is-primary" expanded @click="calcular4"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <p class="title is-5">
              Dias vencimiento pagaré: {{ diasVenPa2 | round }}
            </p>
            <p class="title is-5">Dia descontar: {{ diasDes2 | round }}</p>
            <p class="title is-5">
              Dia inicio a fecha descontar: {{ diasIniDes2 | round }}
            </p>
            <p class="title is-5">
              Valor al vencimiento: {{ valorVen | round }}
            </p>
            <p class="title is-5">
              Valor descontado Banco A: {{ valorDesBancoA | round }}
            </p>
            <p class="title is-5">
              Valor descontado Banco B: {{ valorDesBancoB | round }}
            </p>
            <p class="title is-5">
              Ganancia Absoluta: {{ gananciaAbs | round }}
            </p>
            <p class="title is-5">
              Ganancia relativa: {{ gananciaRel | x100 | round }} %
            </p>
            <p class="title is-5">
              Monto de descostado: {{ montoDescontando | round }}
            </p>
            <p class="title is-5">
              Costo finaciero relativos: {{ costoFinRel | x100 | round }} %
            </p>
            <p class="title is-5">
              Dia de resdescuenta: {{ diaResde | round }}
            </p>
            <p class="title is-5">
              Valor decontado según banco: {{ valorDecontadoBanco | round }}
            </p>
            <p class="title is-5">
              Ganancia absoluta: {{ gananciaAbsDos | round }}
            </p>
            <p class="title is-5">
              Dias entre negociacion: {{ diasNegociacion | round }}
            </p>
            <p class="title is-5">
              Rendimiento o ganancia relativa:
              {{ rendimientoOGanancian | x100 | round }} %
            </p>
            <p class="title is-5">
              Ganancia absoluta obtenida: {{ gananciaAbsObtenida | round }}
            </p>
            <p class="title is-5">
              Ganancia relativa: {{ gananciaRel2 | x100 | round }} %
            </p>
            <p class="title is-5">
              Costo o gastos financiero: {{ costoOGastosFinancieros | round }}
            </p>
            <p class="title is-5">
              Costos financieros relativos:
              {{ costoFinancierosRel | x100 | round }} %
            </p>
          </div>
        </div>
      </div>

      <br />
      <hr />
      <br />

      <p class="title">
        Cantidad Neta Recibida
      </p>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-3">
              <b-field
                label="Valor de Vencimiento"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="valorVen5"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field
                label="Tasa de Descuento"
                :label-position="labelPosition"
              >
                <b-input type="number" placeholder="12%" v-model="descuento5">
                </b-input>
              </b-field>
            </div>
            <div class="column is-6">
              <b-field label="Tiempo" :label-position="labelPosition">
                <b-input type="number" placeholder="9" v-model="periodoN5">
                </b-input>
                <b-select
                  placeholder="Selecciona un tipo"
                  expanded
                  v-model="periodo5"
                >
                  <option
                    :value="item.id"
                    v-for="(item, index) in periodos"
                    :key="index"
                    >{{ item.text }}</option
                  >
                </b-select>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4">
              <b-button type="is-primary" expanded @click="calcular5"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <p class="title is-5">Tiempo: {{ tiempo5 | round }}</p>
            <p class="title is-5">
              Cantidad neta recibida: {{ cantNetaRec5 | round }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  calcularVn,
  calcularInteresesDos,
  calcularNetoPagar,
  calcularDivision,
  calcularTiempo,
  calcularTiempoDescuento,
  calcularPeriodo,
  calcularCantNetaRecibida,
  calcularCantRecibir,
  calcularRendimientoRelativo,
  calcularDias,
  calcularValorDescontado,
  calcularMontoDescontado,
} from '../services/index.js'

export default {
  data() {
    return {
      selected: new Date(),
      showWeekNumber: false,
      locale: undefined,
      labelPosition: 'on-border',
      periodos: [
        { text: 'n/a', id: 0, m: 0 },
        { text: 'Años', id: 1, m: 1 },
        { text: 'Semestres', id: 2, m: 2 },
        { text: 'Cuatrimetres', id: 3, m: 3 },
        { text: 'Trimestres', id: 4, m: 4 },
        { text: 'Bimestres', id: 5, m: 6 },
        { text: 'Meses', id: 6, m: 12 },
        { text: 'Quincenas', id: 7, m: 24 },
        { text: 'Semanas', id: 8, m: 52 },
        { text: 'Días Exactos', id: 9, m: 365 },
        { text: 'Días Aproximados', id: 10, m: 360 },
      ],
      tipos_interes: [
        { text: 'Simple Ordinario', id: 1 },
        { text: 'Exacto', id: 2 },
        { text: 'Bancario', id: 3 },
      ],

      //1
      valorVencimiento1: 0,
      tasa1: 0,
      fechaInicial1: new Date(),
      fechaFinal1: new Date(),
      tiempo1: 0,
      cantNeta1: 0,
      descuento1: 0,

      //2
      valorVencimiento2: 0,
      tasa2: 0,
      periodoN: 0,
      periodo: 0,
      tiempo2: 0,
      cantNeta2: 0,
      descuento2: 0,

      //3
      prestamo1: 0,
      fechaInicial3: new Date(),
      fechaFinal3: new Date(),
      fechaDes1: new Date(),
      tasa3: 0,
      tasaR3: 0,
      diasVenPa: 0,
      diasDes: 0,
      diasIniDes: 0,
      cantRec: 0,
      cantRecTo: 0,
      gananciaTA: 0,
      rendimientoRelativo: 0,
      costoDes: 0,
      interes3: 0,

      //4
      prestamo2: 0,
      fechaInicial4: new Date(),
      fechaFinal4: new Date(),
      fechaDes2: new Date(),
      fechaRedes1: new Date(),
      tasa4: 0,
      bancoA1: 0,
      bancoB1: 0,

      diasVenPa2: 0,
      diasDes2: 0,
      diasIniDes2: 0,
      valorVen: 0,
      valorDesBancoA: 0,
      valorDesBancoB: 0,
      gananciaAbs: 0,
      gananciaRel: 0,
      montoDescontando: 0,
      costoFinRel: 0,
      diaResde: 0,
      valorDecontadoBanco: 0,
      gananciaAbsDos: 0,
      diasNegociacion: 0,
      rendimientoOGanancian: 0,
      gananciaAbsObtenida: 0,
      gananciaRel2: 0,
      costoOGastosFinancieros: 0,
      costoFinancierosRel: 0,

      //5
      valorVen5: 0,
      descuento5: 0,
      periodoN5: 0,
      periodo5: 0,
      tiempo5: 0,
      cantNetaRec5: 0,
    }
  },
  methods: {
    async calcular1() {
      this.tiempo1 = await calcularTiempoDescuento(
        this.fechaInicial1,
        this.fechaFinal1
      )
      this.cantNeta1 = await calcularCantNetaRecibida(
        this.valorVencimiento1,
        this.tasa1,
        this.tiempo1
      )
      this.descuento1 = await calcularInteresesDos(
        this.valorVencimiento1,
        this.cantNeta1
      )
    },
    async calcular2() {
      this.tiempo2 = await calcularPeriodo(
        this.periodos.find((p) => p.id == this.periodo).m,
        this.periodoN
      )

      this.cantNeta2 = await calcularCantNetaRecibida(
        this.valorVencimiento2,
        this.tasa2,
        this.tiempo2
      )

      this.descuento2 = await calcularInteresesDos(
        this.valorVencimiento2,
        this.cantNeta2
      )
    },
    async calcular3() {
      this.diasVenPa = await calcularDias(this.fechaFinal3, this.fechaInicial3)
      this.diasDes = await calcularDias(this.fechaFinal3, this.fechaDes1)

      this.diasIniDes = await calcularDias(this.fechaInicial3, this.fechaDes1)

      this.cantRec = await calcularCantRecibir(
        this.prestamo1,
        this.tasa3,
        this.diasVenPa
      )

      this.cantRecTo = await calcularValorDescontado(
        this.cantRec,
        this.tasaR3,
        this.diasDes
      )

      this.gananciaTA = await calcularInteresesDos(
        this.cantRecTo,
        this.prestamo1
      )

      this.rendimientoRelativo = await calcularRendimientoRelativo(
        this.gananciaTA,
        this.prestamo1,
        this.diasIniDes
      )

      this.costoDes = await calcularInteresesDos(this.cantRec, this.cantRecTo)

      this.interes3 = await calcularRendimientoRelativo(
        this.costoDes,
        this.cantRecTo,
        this.diasDes
      )
    },
    async calcular4() {
      this.diasVenPa2 = await calcularDias(this.fechaFinal4, this.fechaInicial4)
      this.diasDes2 = await calcularDias(this.fechaFinal4, this.fechaDes2)

      this.diasIniDes2 = await calcularDias(this.fechaFinal4, this.fechaRedes1)
      this.valorVen = await calcularCantRecibir(
        this.prestamo2,
        this.tasa4,
        this.diasVenPa2
      )

      this.valorDesBancoA = await calcularValorDescontado(
        this.valorVen,
        this.bancoA1,
        this.diasDes2
      )
      this.valorDesBancoB = await calcularValorDescontado(
        this.valorVen,
        this.bancoB1,
        this.diasIniDes2
      )

      this.gananciaAbs = await calcularInteresesDos(
        this.valorDesBancoA,
        this.prestamo2
      )
      this.gananciaRel = await calcularRendimientoRelativo(
        this.gananciaAbs,
        this.prestamo2,
        this.diasIniDes2
      )

      this.montoDescontando = await calcularMontoDescontado(
        this.valorVen,
        this.bancoB1,
        this.diasDes2
      )

      this.costoFinRel = await calcularRendimientoRelativo(
        this.montoDescontando,
        this.valorDesBancoA,
        this.diasDes2
      )

      this.diaResde = await calcularDias(this.fechaFinal4, this.fechaRedes1)

      this.valorDecontadoBanco = await calcularValorDescontado(
        this.valorVen,
        this.bancoB1,
        this.diaResde
      )

      this.gananciaAbsDos = await calcularInteresesDos(
        this.valorDecontadoBanco,
        this.valorDesBancoA
      )

      this.diasNegociacion = await calcularDias(
        this.fechaRedes1,
        this.fechaDes2
      )

      this.rendimientoOGanancian = await calcularRendimientoRelativo(
        this.gananciaAbsDos,
        this.valorDesBancoA,
        this.diasNegociacion
      )

      this.gananciaAbsObtenida = await calcularInteresesDos(
        this.valorVen,
        this.valorDecontadoBanco
      )

      this.gananciaRel2 = await calcularRendimientoRelativo(
        this.gananciaAbsObtenida,
        this.valorDecontadoBanco,
        this.diaResde
      )

      this.costoOGastosFinancieros = await calcularInteresesDos(
        this.valorVen,
        this.prestamo2
      )

      this.costoFinancierosRel = await calcularRendimientoRelativo(
        this.costoOGastosFinancieros,
        this.prestamo2,
        this.diasVenPa2
      )
    },
    async calcular5() {
      this.tiempo5 = await calcularPeriodo(
        this.periodos.find((p) => p.id == this.periodo5).m,
        this.periodoN5
      )
      this.cantNetaRec5 = await calcularCantNetaRecibida(
        this.valorVen5,
        this.descuento5,
        this.tiempo5
      )
    },
  },
  filters: {
    abs: function(value) {
      if (!value) return 0
      return Math.abs(value)
    },
    x100: function(value) {
      if (!value) return 0
      return Math.abs(value) * 100
    },
    round: function(value) {
      if (!value) return 0
      return Math.round(value * 100) / 100
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>

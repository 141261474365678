<template>
  <section>
    <b-table
      :data="
        search != '' || search != null
          ? data.filter((i) => new RegExp(search, 'i').test(i.name))
          : data
      "
      :bordered="isBordered"
      :striped="isStriped"
      :narrowed="isNarrowed"
      :hoverable="isHoverable"
      :loading="isLoading"
      :focusable="isFocusable"
      :mobile-cards="hasMobileCards"
    >
      <b-table-column label="Nombre" v-slot="props">
        {{ props.row.name }}
      </b-table-column>

      <b-table-column label="Apellido" v-slot="props">
        {{ props.row.lastname }}
      </b-table-column>

      <b-table-column label="Sexo" centered width="200" v-slot="props">
        <span class="center">
          <b-icon
            pack="fas"
            :icon="props.row.gender === 'male' ? 'mars' : 'venus'"
          >
          </b-icon>
        </span>
      </b-table-column>

      <b-table-column label="Edad" centered width="200" v-slot="props">
        <div class="center">
          {{ props.row.age }}
        </div>
      </b-table-column>

      <b-table-column label="País" centered width="200" v-slot="props">
        <div class="center">
          {{ props.row.country }}
        </div>
      </b-table-column>

      <!-- <b-table-column label="Eventos" centered width="200" v-slot="props">
        <div class="center">
          <b-tooltip
            :label="`Editar a ${props.row.name}`"
            position="is-left"
            type="is-info"
          >
            <a class="space-icon" @click="editStepOne(props.row)">
              <b-icon pack="fas" icon="edit" type="is-info" size="is-small">
              </b-icon>
            </a>
          </b-tooltip>

          <b-tooltip
            :label="`Eliminar a ${props.row.name}`"
            position="is-right"
            type="is-danger"
          >
            <a class="space-icon" @click="deleteStepOne(props.row)">
              <b-icon pack="fas" icon="trash" type="is-danger" size="is-small">
              </b-icon>
            </a>
          </b-tooltip>
        </div>
      </b-table-column> -->
    </b-table>
  </section>
</template>

<script>
export default {
  props: {
    data: Array,
  },
  data() {
    return {
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: true,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      search: '',
    }
  },
  methods: {},
  created() {
    // this.$bus.on('searchUsers', (text) => (this.search = text))
  },
}
</script>

<style scoped>
.space-icon {
  margin: 0.3em;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

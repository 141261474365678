<template>
  <div>
    <div class="box space-card">
      <p class="title">
        Flujo efectivo por fechas
      </p>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field label="Fecha de Fin" :label-position="labelPosition">
                <b-datepicker
                  v-model="fecha1"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Tipo Interés" :label-position="labelPosition">
                <b-select
                  placeholder="Selecciona un tipo"
                  expanded
                  v-model="tipoInteres"
                >
                  <option
                    :value="item.id"
                    v-for="(item, index) in tipos_interes"
                    :key="index"
                    >{{ item.text }}</option
                  >
                </b-select>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Tasa Interes" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="tasa1"
                >
                </b-input>
              </b-field>
            </div>
          </div>
          <div v-for="(pago, index) in pagos" :key="index">
            <!-- <p class="subtitle">Pago {{ index + 1 }}</p> -->
            <div class="columns">
              <div class="column is-3">
                <b-field label="Deuda" :label-position="labelPosition">
                  <b-input
                    type="number"
                    placeholder="Escribe un valor"
                    v-model="pago.deuda"
                  >
                  </b-input>
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Fecha" :label-position="labelPosition">
                  <b-datepicker
                    v-model="pago.fecha"
                    :show-week-number="showWeekNumber"
                    :locale="locale"
                    placeholder="Seleccione una fecha"
                    icon="calendar-today"
                    trap-focus
                  >
                  </b-datepicker>
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Tiempo" :label-position="labelPosition">
                  <p class="title is-5">
                    {{ pago.tiempo | round }}
                  </p>
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Monto Actual" :label-position="labelPosition">
                  <p class="title is-5">
                    {{ pago.valorPresente | round }}
                  </p>
                </b-field>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <b-button type="is-primary" expanded @click="calcular"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <p class="title is-5">Total: {{ total | round }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  calcularPeriodo,
  calcularValorFuturo,
  calcularMontoPago,
  calcularInteresesDos,
  calcularValorDeudada,
  calcularValorPresente,
  calcularSuma,
  calcularTiempo,
} from '../services/index.js'

export default {
  data() {
    return {
      selected: new Date(),
      showWeekNumber: false,
      locale: undefined,
      labelPosition: 'on-border',
      periodos: [
        { text: 'n/a', id: 0, m: 0 },
        { text: 'Años', id: 1, m: 1 },
        { text: 'Semestres', id: 2, m: 2 },
        { text: 'Cuatrimetres', id: 3, m: 3 },
        { text: 'Trimestres', id: 4, m: 4 },
        { text: 'Bimestres', id: 5, m: 6 },
        { text: 'Meses', id: 6, m: 12 },
        { text: 'Quincenas', id: 7, m: 24 },
        { text: 'Semanas', id: 8, m: 52 },
        { text: 'Días Exactos', id: 9, m: 365 },
        { text: 'Días Aproximados', id: 10, m: 360 },
      ],
      tipos_interes: [
        { text: 'Simple Ordinario', id: 1 },
        { text: 'Exacto', id: 2 },
        { text: 'Bancario', id: 3 },
      ],

      pagos: [],

      fecha1: new Date(),
      tipoInteres: null,
      tasa1: 0,
      total: 0,
    }
  },
  created() {
    for (let i = 1; i <= 3; i++)
      this.pagos.push({
        deuda: 0,
        fecha: null,
        tiempo: 0,
        valorPresente: 0,
      })
  },
  methods: {
    async calcular() {
      for (let i of this.pagos) {
        if (i.deuda > 0) {
          i.tiempo = await calcularTiempo(
            this.tipoInteres,
            i.fecha,
            this.fecha1
          )

          i.valorPresente = await calcularValorPresente(
            i.deuda,
            this.tasa1,
            i.tiempo
          )
        }

        this.total += i.valorPresente
      }
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>

<template>
  <div id="main">
    <div class="columns">
      <div class="column is-2">
        <Menu />
      </div>
      <div class="column is-10">
        <Home />
      </div>
    </div>
  </div>
</template>

<script>
import Menu from './Menu'
import Home from './Home'
export default {
  components: {
    Menu,
    Home,
  },
}
</script>

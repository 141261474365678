<template>
  <div id="app">
    <!-- <HelloWorld /> -->
    <!-- <Main /> -->
    <div class="columns">
      <div class="column is-2">
        <aside class="menu">
          <div v-for="(item, index) in menu" :key="index">
            <p class="menu-label">
              {{ item.title }}
            </p>
            <ul class="menu-list" v-for="(child, i) in item.children" :key="i">
              <li>
                <a @click="go(child.name)">{{ child.title }}</a>
              </li>
            </ul>
          </div>
        </aside>
      </div>
      <div class="column is-10 top-space">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import HelloWorld from './views/Home.vue'
// import Menu from './views/Menu.vue'
import Main from './views/Main.vue'
import Menu from './utils/menu.js'

export default {
  name: 'App',
  data() {
    return {
      menu: Menu,
    }
  },
  methods: {
    go(name) {
      this.$router.push(`/${name}`)
    },
  },
  components: {
    HelloWorld,
    Main,
  },
}
</script>

//
<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
//   margin-top: 60px;
// }
//
</style>

<style scoped>
.top-space {
  margin-top: 3%;
}
</style>

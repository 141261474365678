<template>
  <div>
    <div class="box space-card">
      <p class="title">
        Costo que tendria o costo total
      </p>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field label="Tasa Interes" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="tasa1"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Prima" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="prima"
                >
                </b-input>
              </b-field>
            </div>
          </div>
          <div v-for="(pago, index) in pagos" :key="index">
            <p class="subtitle">Pago {{ index + 1 }}</p>
            <div class="columns">
              <div class="column is-2">
                <b-field label="Monto" :label-position="labelPosition">
                  <b-input
                    type="number"
                    placeholder="Escribe un valor"
                    v-model="pago.monto"
                  >
                  </b-input>
                </b-field>
              </div>
              <div class="column is-4">
                <b-field label="Tiempo" :label-position="labelPosition">
                  <b-input
                    type="number"
                    placeholder="9"
                    v-model="pago.periodoN"
                  >
                  </b-input>
                  <b-select
                    placeholder="Selecciona un tipo"
                    expanded
                    v-model="pago.periodo"
                  >
                    <option
                      :value="item.id"
                      v-for="(item, index) in periodos"
                      :key="index"
                      >{{ item.text }}</option
                    >
                  </b-select>
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Tiempo" :label-position="labelPosition">
                  <p class="title is-5">
                    {{ pago.resTiempo | round }}
                  </p>
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Monto Actual" :label-position="labelPosition">
                  <p class="title is-5">
                    {{ pago.montoActual | round }}
                  </p>
                </b-field>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <b-button type="is-primary" expanded @click="calcular"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <p class="title is-5">Total: {{ total | round }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  calcularPeriodo,
  calcularValorFuturo,
  calcularMontoPago,
  calcularInteresesDos,
  calcularValorDeudada,
  calcularValorPresente,
  calcularSuma,
} from '../services/index.js'

export default {
  data() {
    return {
      selected: new Date(),
      showWeekNumber: false,
      locale: undefined,
      labelPosition: 'on-border',
      periodos: [
        { text: 'n/a', id: 0, m: 0 },
        { text: 'Años', id: 1, m: 1 },
        { text: 'Semestres', id: 2, m: 2 },
        { text: 'Cuatrimetres', id: 3, m: 3 },
        { text: 'Trimestres', id: 4, m: 4 },
        { text: 'Bimestres', id: 5, m: 6 },
        { text: 'Meses', id: 6, m: 12 },
        { text: 'Quincenas', id: 7, m: 24 },
        { text: 'Semanas', id: 8, m: 52 },
        { text: 'Días Exactos', id: 9, m: 365 },
        { text: 'Días Aproximados', id: 10, m: 360 },
      ],
      tipos_interes: [
        { text: 'Simple Ordinario', id: 1 },
        { text: 'Exacto', id: 2 },
        { text: 'Bancario', id: 3 },
      ],

      pagos: [],
      tasa1: 0,
      prima: 0,
      total: 0,
    }
  },
  created() {
    for (let i = 1; i <= 5; i++)
      this.pagos.push({
        monto: 0,
        periodoN: 0,
        periodo: 0,
        resPago: 0,
        resTiempo: 0,
        montoActual: 0,
      })
  },
  methods: {
    async calcular() {
      // this.tiempo1 = await calcularPeriodo(
      //   this.periodos.find((p) => p.id == this.periodo).m,
      //   this.periodoN
      // )
      for (let i of this.pagos) {
        if (i.monto > 0) {
          i.resTiempo = await calcularPeriodo(
            this.periodos.find((p) => p.id == i.periodo).m,
            i.periodoN
          )
          i.montoActual = await calcularValorFuturo(
            i.monto,
            this.tasa1,
            i.resTiempo
          )
        }

        this.total += i.montoActual
      }

      this.total = await calcularSuma(this.total, this.prima)
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>

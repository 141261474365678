<template>
  <div>
    <div class="box space-card">
      <p class="title">
        Dos Media U Dependientes
      </p>
      <div class="columns">
        <div class="column is-4">
          <img src="img/dosmediaudependientes3.png" alt="" />
        </div>
        <div class="column is-4">
          <img src="img/dosmediaudependientes1.png" alt="" />
        </div>
        <div class="column is-4">
          <img src="img/dosmediaudependientes2.png" alt="" />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="columns" v-for="(item, index) in listaFull" :key="index">
            <div class="column is-1">
              <b-field label="Nro." :label-position="labelPosition">
                <p class="title is-5">{{ index + 1 }}</p>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field label="Muestra 1" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="item.antes"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field label="Muestra 2" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="item.despues"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-1">
              <b-field label="d:" :label-position="labelPosition">
                <p class="title is-5">{{ item.d }}</p>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field label="d-dmedia:" :label-position="labelPosition">
                <p class="title is-5">{{ item.dmedia }}</p>
              </b-field>
            </div>
            <div class="column is-2">
              <b-field label="(d-dmedia)^2:" :label-position="labelPosition">
                <p class="title is-5">{{ item.ddmedia }}</p>
              </b-field>
            </div>
            <div class="column is-2" v-show="index == listaFull.length - 1">
              <b-button
                expanded
                icon-left="plus-circle"
                icon-pack="fas"
                type="is-success"
                @click="addListFull()"
              >
                Añadir
              </b-button>
            </div>
          </div>

          <div class="columns">
            <div class="column is-1">
              <p class="title is-5">Total:</p>
            </div>
            <div class="column is-2">
              <p class="title is-5">{{ totalAntes }}</p>
            </div>
            <div class="column is-2">
              <p class="title is-5">{{ totalDespues }}</p>
            </div>
            <div class="column is-1">
              <p class="title is-5">{{ totalD }}</p>
            </div>
            <div class="column is-2">
              <p class="title is-5">{{ totalDmedia }}</p>
            </div>

            <div class="column is-2">
              <p class="title is-5">{{ totalDDmedia }}</p>
            </div>
          </div>

          <hr />
          <div class="columns">
            <div class="column is-4">
              <b-field
                label="Nivel de significancia"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="nivelSignificancia"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Colas" :label-position="labelPosition">
                <b-select
                  placeholder="Selecciona una cola"
                  expanded
                  v-model="cola"
                >
                  <option
                    :value="item.id"
                    v-for="(item, index) in colas"
                    :key="index"
                    >{{ item.text }}</option
                  >
                </b-select>
              </b-field>
            </div>
            <div class="column is-4">
              <b-button type="is-primary" expanded @click="calcular"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <!-- <div class="columns">
            <div class="column is-12">
              <b-button type="is-primary" expanded @click="calcular"
                >CALCULAR</b-button
              >
            </div>
          </div> -->

          <div>
            <p class="title is-5">n: {{ n | round }}</p>
            <p class="title is-5">D Media: {{ dmedia | round }}</p>
            <p class="title is-5">sd: {{ sd | round }}</p>
            <p class="title is-5">T Calculado: {{ tCalculado | round }}</p>
            <hr />
            <p class="title is-5">
              Grado Libertad: {{ gradoLibertad | round }}
            </p>
            <hr />
            <p class="title is-5">
              Cola Izquierda: {{ colaI | round }} --> Decisión:
              {{ decisionI }}
            </p>
            <p class="title is-5">
              Cola Derecha: {{ colaD | round }} --> Decisión:
              {{ decisionD }}
            </p>
            <p class="title is-5">
              Dos Colas: {{ dosColas | round }} | {{ dosColas2 | round }} -->
              Decisión:
              {{ decisionDos }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  calcularDivision,
  calcularInteresesDos,
  calcularInvT,
  calcularInvNormEstand,
  calcularZCalculado,
  calcularPromedioArray,
  calcularDesviacionMuestral,
  calcularVarianzaMedias,
  calcularZCalculadoDosMedias,
  calcularPromedioArrayJson,
  calcularVarianzaConjunta,
  calcularTCalculadoDosMedias,
  calcularSuma,
  calcularGradoLibertadDif,
  calcularTCalculadoDosMediasDif,
  calcularSd,
  calcularTCalculadoDosMediasDependientes,
} from '../services/index.js'

export default {
  data() {
    return {
      labelPosition: 'on-border',
      periodos: [
        { text: 'n/a', id: 0, m: 0 },
        { text: 'Años', id: 1, m: 1 },
        { text: 'Semestres', id: 2, m: 2 },
        { text: 'Cuatrimetres', id: 3, m: 3 },
        { text: 'Trimestres', id: 4, m: 4 },
        { text: 'Bimestres', id: 5, m: 6 },
        { text: 'Meses', id: 6, m: 12 },
        { text: 'Quincenas', id: 7, m: 24 },
        { text: 'Semanas', id: 8, m: 52 },
        { text: 'Días Exactos', id: 9, m: 365 },
        { text: 'Días Aproximados', id: 10, m: 360 },
      ],
      tipos_interes: [
        { text: 'Simple Ordinario', id: 1 },
        { text: 'Exacto', id: 2 },
        { text: 'Bancario', id: 3 },
      ],
      colas: [
        { id: 1, text: 'Cola Derecha' },
        { id: 2, text: 'Cola Izquierda' },
        { id: 3, text: 'Dos Colas' },
      ],
      lista: [0],

      listaDoble: [{ a: 0, b: 0 }],
      listaFull: [
        { antes: 0, despues: 0, d: null, dmedia: null, ddmedia: null },
      ],
      totalAntes: 0,
      totalDespues: 0,
      totalD: 0,
      totalDmedia: 0,
      totalDDmedia: 0,

      nivelSignificancia: 0,
      cola: null,

      n: 0,
      sd: 0,
      dmedia: 0,
      tCalculado: 0,
      gradoLibertad: 0,

      colaI: null,
      decisionI: '',
      colaD: null,
      decisionD: '',
      dosColas: null,
      dosColas2: null,
      decisionDos: '',
    }
  },
  methods: {
    async calcular() {
      for (let i of this.listaFull) {
        console.log(typeof i.antes)
        if (i.antes && i.despues) {
          i.d = i.despues - i.antes
        }
      }

      this.totalAntes += [...this.listaFull]
        .map((i) => i.antes)
        .reduce((a, v) => parseFloat(a) + parseFloat(v))
      this.totalDespues += [...this.listaFull]
        .map((i) => i.despues)
        .reduce((a, v) => parseFloat(a) + parseFloat(v))
      this.totalD += [...this.listaFull]
        .map((i) => i.d)
        .reduce((a, v) => parseFloat(a) + parseFloat(v))

      this.n = this.listaFull.filter((i) => i.antes && i.despues).length

      this.dmedia = calcularDivision(this.totalD, this.n)

      for (let i of this.listaFull) {
        if (i.antes && i.despues) {
          i.dmedia = i.d - this.dmedia
          i.ddmedia = i.dmedia * i.dmedia

          this.totalDmedia += i.dmedia
          this.totalDDmedia += i.ddmedia
        }
      }

      this.sd = calcularSd(this.totalDDmedia, this.n)

      this.tCalculado = calcularTCalculadoDosMediasDependientes(
        this.n,
        this.dmedia,
        this.sd
      )

      this.gradoLibertad = calcularInteresesDos(this.n, 1)

      this.colaI = 0
      this.colaD = 0
      this.dosColas = 0
      this.dosColas2 = 0
      this.decisionI = ''
      this.decisionD = ''
      this.decisionDos = ''

      if (this.cola == 1) {
        this.colaD = calcularInvT(
          this.cola,
          this.nivelSignificancia,
          this.gradoLibertad
        )
        this.decisionD =
          this.tCalculado > this.colaD ? 'Se rechaza Ho' : 'Se acepta Ho'
      } else if (this.cola == 2) {
        this.colaI = calcularInvT(
          this.cola,
          this.nivelSignificancia,
          this.gradoLibertad
        )
        this.decisionI =
          this.tCalculado < this.colaI ? 'Se rechaza Ho' : 'Se acepta Ho'
      } else {
        this.dosColas = calcularInvT(
          3,
          this.nivelSignificancia,
          this.gradoLibertad
        )
        this.dosColas2 = calcularInvT(
          4,
          this.nivelSignificancia,
          this.gradoLibertad
        )
        this.decisionDos =
          Math.abs(this.tCalculado) > Math.abs(this.dosColas)
            ? 'Se rechaza Ho'
            : 'Se acepta Ho'
      }
    },
    addList() {
      this.lista.push(0)
    },
    addListDouble() {
      this.listaDoble.push({ a: null, b: null })
    },
    addListFull() {
      this.listaFull.push({
        antes: null,
        despues: null,
        d: null,
        dmedia: null,
        ddmedia: null,
      })
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>

<template>
  <div>
    <div class="box space-card">
      <p class="title">
        Valor por Pagar
      </p>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field label="V" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="V"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="d" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="d"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-button type="is-primary" expanded @click="calcular1"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <p class="title is-5">Vn: {{ Vn | round }}</p>
            <p class="title is-5">Descuento: {{ descuento | round }}</p>
          </div>
        </div>
      </div>

      <br />
      <hr />
      <br />

      <p class="title">
        Descuento en Serie
      </p>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field label="Valor nominal" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="valorNominal"
                >
                </b-input>
              </b-field>
            </div>

            <div class="column is-4">
              <b-field label="Descuento Nro. 1" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="descuento1"
                >
                </b-input>
              </b-field>
            </div>

            <div class="column is-4">
              <b-field label="Descuento Nro. 2" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="descuento2"
                >
                </b-input>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column is-4">
              <b-field label="Descuento Nro. 3" :label-position="labelPosition">
                <b-input
                  type="number"
                  placeholder="Escribe un valor"
                  v-model="descuento3"
                >
                </b-input>
              </b-field>
            </div>

            <div class="column is-4">
              <b-button type="is-primary" expanded @click="calcular2"
                >CALCULAR</b-button
              >
            </div>
          </div>

          <div>
            <p class="title is-5">Neto a Pagar: {{ netoPagar | round }}</p>
            <p class="title is-5">
              Descuento total: {{ descuentoTotal | round }}
            </p>
            <p class="title is-5">
              Descuento único: {{ descuentoUnico | x100 | round }} %
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  calcularVn,
  calcularInteresesDos,
  calcularNetoPagar,
  calcularDivision,
} from '../services/index.js'

export default {
  data() {
    return {
      selected: new Date(),
      showWeekNumber: false,
      locale: undefined,
      labelPosition: 'on-border',
      periodos: [
        { text: 'n/a', id: 0, m: 0 },
        { text: 'Años', id: 1, m: 1 },
        { text: 'Semestres', id: 2, m: 2 },
        { text: 'Cuatrimetres', id: 3, m: 3 },
        { text: 'Trimestres', id: 4, m: 4 },
        { text: 'Bimestres', id: 5, m: 6 },
        { text: 'Meses', id: 6, m: 12 },
        { text: 'Quincenas', id: 7, m: 24 },
        { text: 'Semanas', id: 8, m: 52 },
        { text: 'Días Exactos', id: 9, m: 365 },
        { text: 'Días Aproximados', id: 10, m: 360 },
      ],
      tipos_interes: [
        { text: 'Simple Ordinario', id: 1 },
        { text: 'Exacto', id: 2 },
        { text: 'Bancario', id: 3 },
      ],

      //1
      V: 0,
      d: 0,
      Vn: 0,
      descuento: 0,

      //2
      valorNominal: 0,
      descuento1: 0,
      descuento2: 0,
      descuento3: 0,
      netoPagar: 0,
      descuentoTotal: 0,
      descuentoUnico: 0,
    }
  },
  methods: {
    async calcular1() {
      this.Vn = await calcularVn(this.V, this.d)
      this.descuento = await calcularInteresesDos(this.V, this.Vn)
    },
    async calcular2() {
      this.netoPagar = await calcularNetoPagar(
        this.valorNominal,
        this.descuento1,
        this.descuento2,
        this.descuento3
      )
      this.descuentoTotal = await calcularInteresesDos(
        this.valorNominal,
        this.netoPagar
      )
      this.descuentoUnico = await calcularDivision(
        this.descuentoTotal,
        this.valorNominal
      )
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>

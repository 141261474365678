export default [
  // {
  //   title: 'Días',
  //   children: [
  //     { title: 'Exactos', name: 'ExactosFuncionDias' },
  //     { title: 'Aproximado', name: 'ExactosFuncionDiasTresSesenta' },
  //     { title: 'Banqueros', name: 'ExactosBanqueros' },
  //   ],
  // },
  // {
  //   title: 'Interes Simple S',
  //   children: [
  //     {
  //       title: 'Cuando se ocupa el valor futuro o los intereses ganados',
  //       name: 'InteresSimpleSUno',
  //     },
  //     {
  //       title: 'Cuando se presenta el valor presente o los intereses ganados',
  //       name: 'InteresSimpleSDos',
  //     },
  //     { title: 'Cuando se ocupa tasa de interés', name: 'InteresSimpleSTres' },
  //     {
  //       title: 'Cuando se ocupa el tiempo y el valor futuro',
  //       name: 'InteresSimpleSCuatro',
  //     },
  //   ],
  // },
  // {
  //   title: 'Interes Simple I',
  //   children: [
  //     {
  //       title: 'Cuando se ocupa el capital y el valor futuro',
  //       name: 'InteresSimpleIUno',
  //     },
  //     {
  //       title: 'Cuando se ocupa la tasa de interes y el valor futuro',
  //       name: 'InteresSimpleIDos',
  //     },
  //     {
  //       title: 'Cuando se ocupa la tasa de interes y el valor futuro',
  //       name: 'InteresSimpleITres',
  //     },
  //   ],
  // },
  // {
  //   title: 'Descuento',
  //   children: [
  //     { title: 'Valor por Pagar', name: 'DescuentoUno' },
  //     {
  //       title: 'Cuando se ocupa cantidad neta recibida y descuento',
  //       name: 'DescuentoDos',
  //     },
  //     { title: 'Valor futuro', name: 'DescuentoTres' },
  //     { title: 'Descuento Único', name: 'DescuentoCuatro' },
  //   ],
  // },
  // {
  //   title: 'Pagos Parciales',
  //   children: [
  //     { title: 'Regla Comercial', name: 'PagosParciales' },
  //     {
  //       title: 'Regla de estados unidos o reglas de los saldos insolutos',
  //       name: 'PagosParcialesDos',
  //     },
  //     { title: 'Regla del lagarto o cocodrilo', name: 'PagosParcialesTres' },
  //   ],
  // },
  // {
  //   title: 'Series Pagos',
  //   children: [
  //     {
  //       title: 'Costo actual o costo hoy (Valor Presente)',
  //       name: 'SeriesPagosUno',
  //     },
  //     { title: 'Costo que tendria o costo total', name: 'SeriesPagosDos' },
  //   ],
  // },
  // {
  //   title: 'Flj. Efec. por Fechas',
  //   children: [{ title: 'Flujo efectivo por fechas', name: 'FlujoEfectivo' }],
  // },
  // {
  //   title: 'Prueba Pendiente',
  //   children: [{ title: 'Prueba Pendiente', name: 'PruebaPendiente' }],
  // },
  // {
  //   title: 'Hipo',
  //   children: [
  //     { title: 'Hipo 1 Muestra Z', name: 'HipoUnoMuestraZ' },
  //     { title: 'Hipo 1 Muestra T', name: 'HipoUnoMuestraT' },
  //     { title: 'Hipo 1 Muestra T Resumido', name: 'HipoUnoMuestraTR' },
  //   ],
  // },
  // {
  //   title: 'Dos Medias',
  //   children: [
  //     { title: 'Dos Media U con Z', name: 'DosMediaUUno' },
  //     { title: 'Dos Media U con T', name: 'DosMediaUDos' },
  //     { title: 'Dos Media U T Resumido', name: 'DosMediaUTres' },
  //     { title: 'Dos Media U T Diferentes', name: 'DosMediaUCuatro' },
  //     { title: 'Dos Media U T Diferent Resums', name: 'DosMediaUCinco' },
  //     { title: 'Dos Media U Dependientes', name: 'DosMediaUSeis' },
  //   ],
  // },
  {
    title: 'Estimación',
    children: [
      { title: 'Estimación Desviación', name: 'EstDesUno' },
      { title: 'Estimación Proporción', name: 'EstDesDos' },
      { title: 'Inv Conf Media Z', name: 'EstDesTres' },
      { title: 'Inv Conf Z Media Finita', name: 'EstDesCuatro' },
      { title: 'Inv Conf Z Proporcion', name: 'EstDesCinco' },
      { title: 'Inv Conf Proporcion Finita', name: 'EstDesSeis' },
      { title: 'Inv Conf T Datos', name: 'EstDesSiete' },
    ],
  },
  {
    title: 'Probabilidad',
    children: [
      { title: 'Dist. Prob. Discreta', name: 'ProbUno' },
      { title: 'Dist. Prob. Discreta Frecuencia', name: 'ProbDos' },
      { title: 'Dist. Binomial', name: 'ProbTres' },
      { title: 'Dist. Hispergeometrica', name: 'ProbCuatro' },
      { title: 'Dist. Poisson', name: 'ProbCinco' },
    ],
  },
  {
    title: 'Dist. Continua',
    children: [
      { title: 'Probabilidad Z', name: 'DistContUno' },
      { title: 'Valor X', name: 'DistContDos' },
      { title: 'Teorema Limite Central', name: 'DistContTres' },
    ],
  },
]

<template>
  <div>
    <div class="box space-card">
      <p class="title">EXACTOS</p>
      <p class="subtitle">Funcion Dias</p>
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field label="Fecha de Inicio" :label-position="labelPosition">
                <b-datepicker
                  v-model="efd_date_start"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Fecha de Fin" :label-position="labelPosition">
                <b-datepicker
                  v-model="efd_date_end"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-4">
              <b-button
                type="is-primary"
                expanded
                @click="calcularFuncionDias()"
                >CALCULAR</b-button
              >
            </div>
          </div>
          <div>
            <p class="subtitle">Días exactos:</p>
            {{ resultado_efd | round }}
          </div>
        </div>
      </div>

      <br />
      <hr />
      <br />
      <div class="columns">
        <div class="column">
          <div class="columns">
            <div class="column is-4">
              <b-field label="Fecha de Inicio" :label-position="labelPosition">
                <b-datepicker
                  v-model="efd_date_start_sum"
                  :show-week-number="showWeekNumber"
                  :locale="locale"
                  placeholder="Seleccione una fecha"
                  icon="calendar-today"
                  trap-focus
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field
                label="Más días adicionales"
                :label-position="labelPosition"
              >
                <b-input
                  type="number"
                  v-model="efd_days_sum"
                  placeholder="Escribe un numero"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-4">
              <b-button type="is-primary" expanded @click="calcularSumaDias()"
                >CALCULAR</b-button
              >
            </div>
          </div>
          <div>
            <p class="subtitle">Fecha final:</p>
            {{ resultado_efd_sum }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { calcularDias, sumarDias } from '../services/index.js'

export default {
  data() {
    return {
      selected: new Date(),
      showWeekNumber: false,
      locale: undefined,
      labelPosition: 'on-border',

      //1
      efd_date_start: new Date(),
      efd_date_end: new Date(),
      resultado_efd: 0,

      //2
      efd_date_start_sum: new Date(),
      efd_days_sum: 0,
      resultado_efd_sum: 0,
    }
  },
  methods: {
    calcularFuncionDias() {
      this.resultado_efd = calcularDias(this.efd_date_start, this.efd_date_end)
    },
    calcularSumaDias() {
      this.resultado_efd_sum = sumarDias(
        this.efd_date_start_sum,
        this.efd_days_sum
      )
    },
  },
}
</script>

<style scoped>
.space-card {
  margin: 0 10% 5% 10%;
}
</style>
